<div class="list-container">
    <ng-container *ngFor="let id of stopIdArr; let index = index">
        <!-- <div class="item" [ngClass]="{'selected': item.id == selected}" (click)="selectStop(item.id)">
            <span>Stop {{item.index}} - {{getTypeText(item)}}</span>
            <div *ngIf="shouldShowNeedConfirmIcon(item)">
                <img src="/assets/svg/blue_clock.svg">
            </div>
        </div> -->
        <div style="width: 100%;" pod-confirmation-stop-detail [stop]="getStopEntityById(id)" [index]="index+1"></div>
    </ng-container>
    <ng-container *ngIf="!stopList?.length">
        <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 5, width: ['100%', '100%', '100%', '100%','100%'] }"></nz-skeleton>
    </ng-container>
</div>
