import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import { Stop } from "@wearewarp/types/data-model";
import TaskEntity from "./TaskEntity";
import { getInjector } from "@services/injector";
import { PodService } from "../pod.service";
import { Const, WarpId } from "@wearewarp/universal-libs";
import { DateUtil } from "@services/date-utils";
import { P } from "@antv/g2plot";

export default class StopEntity {
  private data: Stop;
  private pods: any;
  private index: number;
  constructor(private podService : PodService) {}

  async init(data) {
    this.data = data;
    let copyData = JSON.parse(JSON.stringify(data));
    let pods = [...copyData?.pods];
    let handledPodIds = [];
    let handlePods = []
    for(let pod of pods){
      if(!handledPodIds.includes(pod._id)) {
        handledPodIds.push(pod._id);
        pod.taskIds = [pod.taskId];
        delete pod.taskId;
        handlePods.push(pod)
      }else{
        let handlePod = handlePods.filter(it => (it._id === pod._id))[0];
        handlePod.taskIds.push(pod.taskId)
      }
    }
    let podAllTasks = [];
    for(let item of handlePods){
      if(item.taskIds.length === this.data.taskIds.length && this.data.taskIds.length > 1) podAllTasks.push(item);
    }
    let stopLevelPODIds = podAllTasks.map(it => it._id);
    let podShipmentLevel = data?.pods?.filter(it => (!stopLevelPODIds.includes(it._id)));
    this.pods = [...podAllTasks,...podShipmentLevel]
    for(let pod of this.pods){
      pod.stopId = this.data.id
    }
    return this;
  }

  setIndex(index) {
    this.index = index;
    return this;
  }

  getIndex() {
    return this.index
  }
  getType() {
    return this.data.type
  }

  getId() {
    return this.data.id
  }

  getStatus() {
    return this.data.status
  }

  getLocationName() {
    return this.data.info.locationName || "No Name"
  }

  getAddressText() {
    return MasterData.getAddressText(this.data.info.addr)
  }

  getAddress() {
    return this.data.info.addr
  }

  getDeliveryInfo() {
    return this.data.info
  }

  getAppointment() {
    const appointment = this.data.info.appointmentInfo;
    if (appointment?.from) return appointment
    const timeWindow = this.data.info?.windows?.[0]
    if (timeWindow?.from) return timeWindow;
  }

  isAppointmentSchduled() {
    return this.data.info?.appointmentInfo?.from ? true : false;
  }

  getAppointmentIssue() {
    if (this.data.info?.requiresAppointment && !this.isAppointmentSchduled()) return "Requires Appointment";
    if (this.data?.metadata?.conflictTime) return "Conflict time between shipments"
  }

  isMixedStatus() {
    return this.data?.metadata?.mixedStatus
  }

  getTimezone() {
    return this.data?.info?.addr?.metadata?.timeZoneStandard
  }

  getFirstTask(): TaskEntity | undefined {
    return this.getTasks()?.find(task => ![Const.TaskStatus.canceled, Const.TaskStatus.failed, Const.TaskStatus.pickupFailed].includes(<any>task.getStatus()));
  }

  getArrivedTime() {
    return this.getFirstTask()?.getArrivedTime()
  }

  getDepartedTime() {
    return this.getFirstTask()?.getDepartedTime()
  }

  getETA() {
    return this.getFirstTask()?.getETA()
  }

  getTotalPod() {
    return this.getFirstTask()?.getTotalPod() ?? 0;
  }


  getRefNums() {
    let refNums = [];
    for (let task of this.getTasks()) {
      refNums = [ ...refNums, ...task.getRefNums() ];
    }
    return Utils.uniqElementsArray(refNums);
  }

  getTasks() {
    return this.data.taskIds.map(taskId => this.podService.getTaskById(taskId))
  }

  getShipments() {
    return this.getTasks().map(task => task.getShipment()).filter(x => x);
  }

  getShipmentDeliveryInfo() {
    if (this.getType() == Const.TaskType.PICKUP) {
      return this.getShipments().map(item => item.getPickInfo());
    } else if (this.getType() == Const.TaskType.DROPOFF) {
      return this.getShipments().map(item => item.getDropInfo());
    }
    return []
  }

  isExistsReturnTask() {
    let arr = this.getTasks().filter(task => task?.isExistsReturn());
    return arr.length ? true : false;
  }

  getWarningStatus() {
    if (this.getTasks().length <= 1) return null;
    let statusTasks = this.getTasks().map(task => task.getStatus());
    let isSame = true;
    for (let i=0; i<statusTasks.length; i++) {
      if (statusTasks[i] !== statusTasks[0]) {
        isSame = false;
        break;
      }
    }
    if (isSame) {
      return null;
    } else {
      return this.getTasks().map(task => `Shipment ${WarpId.showShipment(task.getShipment()?.getWarpId())}: ${Utils.capitalizeFirstLetter(task.getStatus())}`);
    }
  }

  getWarningAppointment() {
    if (this.getTasks().length <= 1) return null;
    let getDisplayTime = (window, timezone) => {
      return DateUtil.displayTimeWindow(window, {
        timezone: timezone,
        formatDateOnly: 'MM/DD/YY',
        format: "MM/DD/YY h:mm:ss A",
      })
    }
    const appointment = this.data.info.appointmentInfo;
    if (appointment?.from) {
      let appointments = this.getTasks().map(task => task?.toJSON().info?.appointmentInfo);
      let isSame = true;
      for (let i=0; i<appointments.length; i++) {
        if (appointments[i]?.from !== appointments[0]?.from || appointments[i]?.to !== appointments[0]?.to) {
          isSame = false;
          break;
        }
      }
      if (isSame) {
        return null;
      } else {
        return this.getTasks().map(task =>
          (`Shipment ${WarpId.showShipment(task.getShipment()?.getWarpId())}:
          ${getDisplayTime(task?.toJSON().info?.appointmentInfo, this.getTimezone())}`)
        );
      }
    }

    const timeWindow = this.data.info?.windows?.[0]
    if (timeWindow?.from) {
      let timeWindows = this.getTasks().map(task => task?.toJSON().info?.windows?.[0]);
      let isSame = true;
      for (let i=0; i<timeWindows.length; i++) {
        if (timeWindows[i]?.from !== timeWindows[0]?.from || timeWindows[i]?.to !== timeWindows[0]?.to) {
          isSame = false;
          break;
        }
      }
      if (isSame) {
        return null;
      } else {
        return this.getTasks().map(task =>
          (`Shipment ${WarpId.showShipment(task.getShipment()?.getWarpId())}:
          ${getDisplayTime(task?.toJSON().info?.windows?.[0], this.getTimezone())}`)
        );
      }
    }
  }

  getWarningPod() {
    let tasksNumber = this.getTasks().length;
    if (tasksNumber <= 1) return null;
    let podTasks =this.getTasks().map(task => task.getPodUploadFiles());
    let isSameLength = true;
    for (let i=1; i<podTasks.length; i++) {
      if (podTasks[i].length != podTasks[0].length) {
        isSameLength = false;
        break;
      }
    }
    if (!isSameLength) {
      return `Pods of ${tasksNumber} tasks are diffirent.`;
    } else {
      let arr = podTasks.map(pods => pods.map(pod => String(pod).trim()).sort());
      let isSameContent = true;
      for (let i=1;i<arr.length; i++) {
        if (!Utils.isArraysTheSame(arr[i], arr[0])) {
          isSameContent = false;
          break;
        }
      }
      if (isSameContent) {
        return null;
      } else {
        return `Pods of ${tasksNumber} tasks are diffirent.`;
      }
    }
  }

  getShipmentClientIds() {
    let clientIds = this.getShipments().map(item => item.getClientId());
    return Utils.uniqElementsArray(clientIds);
  }

  getPods(){
    return this.pods;
  }
  
  toJSON(){
    return this.data
  }
}
