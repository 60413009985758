import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BaseDialog } from '@dialogs/base-dlg';

@Component({
  selector: 'pc-view-image-only',
  templateUrl: './view-image-only.html',
  styleUrls: []
})
export class ViewImageOnlyComponent extends BaseDialog {
  @Input() imgUrl: string;
  @Input() fileImage: File | Blob;
  constructor(private sanitizer: DomSanitizer) {
    super();
  }
}
