import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PODLocalFile, PodService } from '../../pod.service';
import { AttachedFile } from '@wearewarp/types/data-model';
import { AttachedFileUtil } from '@services/attached-file-util';
import * as pdfjsLib from "pdfjs-dist";
import { ImageUtil } from '@services/image-util';
import { BaseComponent } from '@abstract/BaseComponent';
import { Const } from '@const/Const';
import StopEntity from '../../Entity/StopEntity';
import { Utils } from '@services/utils';
pdfjsLib.GlobalWorkerOptions.workerSrc = "assets/mozilla/pdfjs-2.13.216/pdf.worker.min.js";

@Component({
  selector: '[list-pod-pod-confirmation]',
  templateUrl: './list-pod.component.html',
  styleUrls: ['./list-pod.component.scss']
})
export class ListPodPodConfirmation extends BaseComponent{
  @Input() enableAddPOD: boolean = true;
  @Input() pods: PODLocalFile[] = [];
  @Input() stop : StopEntity;
  @Input() taskId: string='';
  @ViewChild('hasBeenConfirmed') hasBeenConfirm: TemplateRef<void>;
  @ViewChild('needConfirm') needConfirm: TemplateRef<void>
  public selectedPOD: PODLocalFile;
  public isUploading: boolean = false;
  public load: string;
  constructor(private podService: PodService) {
    super();
    this.load = Math.floor(Math.random() * 10000000000).toString();
   }

  ngOnInit(){}

  ngOnChanges(): void {
    this.preparePodUrlForTask()
  }

  private async preparePodUrlForTask() {
    if (Utils.isArrayNotEmpty(this.pods)) {
      for (let i = 0; i < this.pods.length; i++) {
        let podItem = this.pods[i];
        if(!this.isPdf(podItem)) continue;
        this.loadPdfPod(podItem,i);
      }
    }
  }

  public isPdf(item: AttachedFile): boolean {
    return AttachedFileUtil.isPdf(item);
  }

  public async loadPdfPod(podItem,index){
    let url = await podItem.localUrl();
    podItem.loadingTask = pdfjsLib.getDocument({url, withCredentials: true});
    podItem.loadingTask.promise.then(
        (pdf) => {
        pdf.getPage(1).then( (page) => {
          var desiredWidth = 48; // css class attached-pod
          var viewport = page.getViewport({ scale: 1 });
          var scale = desiredWidth / viewport.width;
          var scaledViewport = page.getViewport({ scale: scale });
          var canvas;
          if(podItem?.taskId)
            canvas = <HTMLCanvasElement>(
              document.getElementById(`task-${podItem.taskId}-pod-${index}-${this.load}`)
            );
          else 
            canvas = <HTMLCanvasElement>(
              document.getElementById(`stop-${this.stop.getId()}-pod-${index}-${this.load}`)
            );
          if (!canvas) return;
          var context = canvas.getContext("2d");
          canvas.height = scaledViewport.height;
          canvas.width = scaledViewport.width;
          var renderContext = {
            canvasContext: context,
            viewport: scaledViewport,
          };
          var renderTask = page.render(renderContext);
          renderTask.promise
            .then()
            .catch((e) => console.error("DPF render error ", e));
        });
      },
      function (err) {
        console.error("PDF loading error ", err);
      }
    );
  }

  public isSelected(item: PODLocalFile){
    //pod stop level ko có trường taskId
    if(this.selectedPOD?._id !== item?._id) return false;
    if(this.selectedPOD?.stopId !== item?.stopId) return false;
    if(item?.taskId){
      if(!this.selectedPOD?.taskId) return false;
      if(this.selectedPOD?.taskId !== item?.taskId) return false;
      return true;
    }else{
      if(this.selectedPOD?.taskId) return false;
    }
    return true;
  }

  isConfirmPOD(item) {
    if (item.podConfirmed?.by && item.podConfirmed?.when) return true;
    return false;
  }

  public getIndexForPod(podId: string){
    for(let i = 0; i < this.pods.length; i++){
      if(this.pods[i]?._id === podId) return i;
    }
  }

  getTemplateType(item){
    if(this.isConfirmPOD(item)) return this.hasBeenConfirm;
    return this.needConfirm;
  }

  onFileSelected(inputElement: HTMLInputElement) {
    let file = inputElement.files[0];
    inputElement.value = "";
    ImageUtil.resizeImageIfNeeded(file).then((blob) =>{
      if(this.taskId)
        this.uploadPOD(blob, blob.name, [this.taskId]);
      else {
        let tasks = this.stop.getTasks();
        this.uploadPOD(blob, blob.name, tasks.map(task => task.getId()));
      }
    }
    );
  }

  private async uploadPOD(file: Blob, fileName: string, taskIds: string[]) {
    this.isUploading = true;
    let apiUrl = `${Const.APIV2(Const.APIURI_TASKS)}/uploadPodForListTasksOfStop`;
    let formData = new FormData();
    const jsonData = {
      'taskIds': taskIds,
    }
    formData.append("params", JSON.stringify(jsonData));
    formData.append("uploadPOD", file, fileName);
    this.api.postFormData(apiUrl, formData).subscribe(
      (resp) => {
        this.isUploading = false;
        this.refreshPodChange()
        this.showSuccess("Your POD has been added successfully.", {nzPlacement: 'bottomRight'});
      },
      (err) => {
        this.isUploading = false;
        this.showErr(err, {nzPlacement: 'bottomRight'});
      }
    );
  }

  onClickAddPod(event){
    let element: HTMLElement;
    element = document.getElementById(`stop-${this.stop.getId()}-uploadRoutePODInput-${this.taskId}-${this.load}`) as HTMLElement;
    element.click();
    //event.stopPropagation();
  }

  private refreshPodChange(){
    let jobId = this.podService.getpresentJob()?.id;
    let stopId = this.stop.getId();
    this.podService.fetchRoute(jobId, stopId, this.taskId ? this.taskId : null);
  }

  

}
