import { EventEmitter, Injectable } from "@angular/core";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { Job, ShipmentItem } from "@wearewarp/types/data-model";
import { AttachedFile } from '@wearewarp/types/data-model';
import { BehaviorSubject, Observable, Subject } from "rxjs";
import StopEntity from "./Entity/StopEntity";
import ShipmentEntity from "./Entity/ShipmentEntity";
import TaskEntity from "./Entity/TaskEntity";
import { AttachedFileUtil } from "@services/attached-file-util";
import { takeUntil } from "rxjs/operators";
import { JobV2 } from "./interface";
import { PodMatchShipment, PodMatchShipmentForPage } from "@wearewarp/types/data-model/types/PodAnalysis";


export interface JobPaginationInfo {
  count: number,
  limit: number,
  skip: number,
  total: number
}
export interface PODLocalFile extends AttachedFile {
  localUrl?: any,
  taskId?: string, //POD level Shipment thì có trường taskId này
  stopId?:string,
  taskIds?: string // POD level stop thì không có trường taskId mà chỉ có taskIds 
  loadingTask?: any
  isPdf?: boolean,
  shipmentMatching?: Array<PodMatchShipment>,     // auto suggest pod with shipment
  shipmentMatchingForPages?: Array<PodMatchShipmentForPage>,     // dùng cho những file pdf nhiều trang
}

var CACHE_LOCAL_PODS: Map<string, any> = new Map();

@Injectable({
  providedIn: 'root'
})
export class PodService {
  private presentJob: JobV2 | null;
  private selectedStop: StopEntity | null;
  private selectedTask: TaskEntity | null;
  private stopEntities: StopEntity[];
  private podArray: PODLocalFile[] = []; //lưu pod của stop hiện tại để xử lí next, back 
  private shipmentItemsMap: Map<string, ShipmentItem> = new Map();
  private shipmentEntities: Map<string, ShipmentEntity> = new Map();
  public notifyCancelReq = new Subject<boolean>();
  private podArrayMap: Map<string, PODLocalFile[]> = new Map(); //lưu pod của job 
  private taskEntities: Map<string, TaskEntity> = new Map();
  public loading : BehaviorSubject<boolean> = new BehaviorSubject(true);
  public job: BehaviorSubject<any> = new BehaviorSubject(null);
  public podsChange: EventEmitter<any> = new EventEmitter();
  constructor(private api: ApiService) { }
  public async fetchJobSelected() {
    let promises = [
      this.fetchStops(),
      this.fetchTasks(),
      this.fetchShipments(),
      this.fetchShipmentItems()
    ];
    await Promise.all(promises).catch(e => {
      console.log(e);
    })
  }

  

  public async fetchStops() {
    try {
      let url = Const.APIV2(`${Const.APIURI_POD_CONFIRMATION}/${this.presentJob.id}/pods`);
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data.list_data;
      this.stopEntities = await Promise.all(data.map(async (stop, index) => (await new StopEntity(this).setIndex(index).init(stop))));
    }
    catch (e) {
      console.log(e)
    }
  }

  public async fetchTasks(): Promise<void> {
    try {
      let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.presentJob.id}/tasks`);
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data?.list_data;
      for (let item of data) {
        const taskEntity = new TaskEntity(this).init(item);
        this.taskEntities.set(taskEntity.getId(), taskEntity);
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  public async fetchShipments(): Promise<void> {
    try {
      let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.presentJob.id}/shipments`);
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data?.list_data;
      for (let item of data) {
        const shipmentEntity = new ShipmentEntity(this).init(item);
        this.shipmentEntities.set(shipmentEntity.getId(), shipmentEntity);
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  public async fetchShipmentItems(): Promise<void> {
    try {
      let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.presentJob.id}/shipment_items`);
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data?.list_data;
      for (let item of data) {
        this.shipmentItemsMap.set(item.id, item);
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  public async fetchRoute(jobId: string, stopId?: string, taskId?: string, loading = true) {
    try {
      if(loading) this.loading.next(true);      
      //đổi route clear podArrayMap 
      if(this.presentJob?.id !== jobId){
        this.podArrayMap.clear();
        this.clearCache()
      }
      let url = `${Const.APIURI_POD_CONFIRMATION_V2('job')}/${jobId}`;
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data;
      this.presentJob = data;
      await this.fetchJobSelected();
      for (let stop of Array.from(this.stopEntities.values())) {
        let pods = this.filterPodForStop(stop.getPods(), stop.toJSON().taskIds);
        pods = this.processPods(pods);
        this.podArrayMap.set(stop.getId(), pods);
      }
      this.selectedStop = this.stopEntities[0];
      if(stopId){
        this.selectedStop = this.stopEntities.find((it: StopEntity) => it.getId() === stopId)
      }
      this.podArray = [];
      if(taskId){
        this.selectedTask = this.taskEntities.get(taskId);
        this.podArray = this.podArrayMap.get(this.selectedStop.getId()).filter(it => it?.taskId === taskId);
      }else{
        this.selectedTask = null;
        this.podArray = this.podArrayMap.get(this.selectedStop.getId()).filter(it => !it?.taskId);
      }
      this.job.next(this.presentJob);
      this.podsChange.emit();
      this.loading.next(false)
    }
    catch (e) {
      console.log(e)
    }
  }

  

  public getStops() {
    return this.stopEntities;
  }

  public getpresentJob() {
    return this.presentJob;
  }

  

  private processPods(podArray) {
    return podArray.map((item, index) => {
      return {
        ...item,
        index,
        localUrl: () => this.attachedFileUrl(item),
        isPdf: AttachedFileUtil.isPdf(item),
        createdAt: item?.insert.when,
        podConfirmed: item?.podConfirmed || {},
      };
    });
  }

  public getSelectedStop() {
    return this.selectedStop;
  }

  public async setSelectedStopAndTask(stopId: string, taskId?: string) {
    this.selectedStop = this.stopEntities.filter(stop => (stop.getId() === stopId))[0];
    if(taskId){
      this.selectedTask = this.taskEntities.get(taskId);
      this.podArray = this.podArrayMap.get(this.selectedStop.getId()).filter(it => it?.taskId === taskId);
    }else{
      this.selectedTask = null;
      this.podArray = this.podArrayMap.get(this.selectedStop.getId()).filter(it => !it?.taskId);
    }
    this.podsChange.emit();
  }


  public getStopById(stopId) {
    return this.stopEntities.filter(stop => (stop.getId() === stopId))[0];
  }

  public getPodByStop(stopId: string){
    return this.podArrayMap.get(stopId)
  }

  public getShipmentEntities(){
    return Array.from(this.shipmentEntities.values());
  }

  public getShipmentById(shipmentId: string) {
    return this.shipmentEntities.get(shipmentId);
  }

  public getShipmentItemById(itemId: string) {
    return this.shipmentItemsMap.get(itemId);
  }

  public getSelectedTask(){
    return this.selectedTask;
  }

  public getTaskById(taskId: string) {
    return this.taskEntities.get(taskId);
  }

  public getTaskEntities() {
    return Array.from(this.taskEntities.values());
  }

  isConfirmPOD(item) {
    if (item.podConfirmed?.when) return true;
    return false;
  }

  public filterPodForStop(pods: any[], taskIds: string[]) {
    let podAllTasks = pods.filter(it => !it?.taskId); //stop level
    let podEachTask = pods.filter(it => (it?.taskId));// shipment level
    let flag = 0;
    for (let taskId of taskIds) {
      let i: any = {};
      i.pods = podEachTask.filter(it => {
        return it.taskId === taskId;
      }) || [];
      if (i.pods.length !== podAllTasks.length) {
        flag = 1;
        break;
      }
    }
    if (!flag) return podAllTasks;
    else return pods;
  }

  public async attachedFileUrl(pod: PODLocalFile, ...otherParams) {
    if(pod.uploadProgress == 'UPLOADING'){
      pod.localUrl = 'https://warp-public-media.s3.us-west-2.amazonaws.com/images/photo-uploading.png';
      return pod.localUrl;
    }

    const cached = CACHE_LOCAL_PODS.get(pod._id);
    if(cached) pod.localUrl = cached;
    if (pod.localUrl) return pod.localUrl;
   
    if(AttachedFileUtil.isPdf(pod)){
      const podUrl = AttachedFileUtil.attachedFileUrl(pod);
      const data = await this.api.download(podUrl).toPromise();
      const file = new Blob([data], { type: pod.type });
      const fileURL = URL.createObjectURL(file);
      CACHE_LOCAL_PODS.set(pod._id, fileURL);
      return podUrl;
    }
    const podUrl = AttachedFileUtil.attachedFileUrl(pod, true);
    const response = await this.api.GET(`${podUrl}?no-redirect=1`).pipe(takeUntil(this.notifyCancelReq)).toPromise();
    if(response){
      pod.localUrl = response?.data?.url;
    }    
    return pod.localUrl;
  }

  private async clearCache(){
    const caches = Array.from(CACHE_LOCAL_PODS.values());
    caches.map(url => URL.revokeObjectURL(url));
    CACHE_LOCAL_PODS.clear()
  }


  public getPodArray(){
    return this.podArray;
  }
  public getPodArrayMap(){
    return this.podArrayMap;
  }

  public getSelectedStopIndex(){
    let index = 0;
    for(let i = 0; i< this.stopEntities.length; i++){
      if(this.stopEntities[i].getId() === this.selectedStop.getId()) index = i;
    } return index + 1;
  }

  public clearOldData(){
    this.job.next(null);
    this.shipmentEntities.clear();
    this.shipmentItemsMap.clear();
    this.taskEntities.clear();
  }
}
