import { Component, Input, OnInit } from '@angular/core';
import { BaseDrawer } from '@app/drawers/base-drawer';

@Component({
  selector: 'app-note-and-history',
  templateUrl: './note-and-history.component.html',
  styleUrls: ['./note-and-history.component.scss']
})
export class NoteAndHistory extends BaseDrawer {
  @Input() jobId: string;
  constructor() { 
    super()
  }

  ngOnInit(): void {
  }

  closePopup() {
    this.nzDrawer.close()
  }

}
