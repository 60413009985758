<div *ngIf="!onProgress" class="pod-confirm-container">
    <div class="header-container" pod-confirmation-header [job]="job"></div>
    <nz-tabset style="position: relative;">
        <nz-tab nzTitle="Confirm POD">
            <div class="content-container">
                <div class="stop-list" pod-confirmation-stop-list [stopList]="stopEntities"></div>
                <div class="pod-container" pod-detail></div>
            </div>
            <!-- <div class="mark-button">
                <button nz-button *ngIf="job?.isNeedPODConfirm" 
                    (click)="onMarkAsConfirmedPOD()" 
                    nzType="primary" 
                    [disabled]="isDisableBtnMarkAsConfirmedPOD()"
                    [nzLoading]="onProgress"
                >
                    <span nz-icon nzType="check-circle" nzTheme="outline"></span>
                    Mark as Confirmed POD
                </button>
                <div *ngIf="job && !job?.isNeedPODConfirm">
                    {{getMarkAsConfirmedWhenBy('POD')}}
                </div>
            </div> -->
        </nz-tab>
        <nz-tab nzTitle="Confirm Rate">
            <rate-confirmation [job]="job"></rate-confirmation>
            <!-- <div class="mark-button">
                <button nz-button 
                    *ngIf="job?.isNeedRateConfirm" 
                    (click)="onMarkAsConfirmedRate()" 
                    nzType="primary" 
                    [nzLoading]="onProgress"
                >
                    <span nz-icon nzType="check-circle" nzTheme="outline"></span>
                    Mark as Confirmed Rate
                </button>
                <div *ngIf="job && !job?.isNeedRateConfirm">
                    {{getMarkAsConfirmedWhenBy('Rate')}}
                </div>
            </div> -->
        </nz-tab>
    </nz-tabset>
</div>
<div *ngIf="onProgress" class="onProgress">
    <div class="item">
        <img width="100px" height="100px" src="/assets/img/pod_confirmation/scanning_file.gif" alt="">
        <div class="loading">Scanning document...</div>
        <div>Take a sip of water and wait a moment</div>
    </div>
</div>
