import { BaseComponent } from '@abstract/BaseComponent';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pc-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss', '../../style.scss']
})
export class PaymentComponent extends BaseComponent{
  @Input() paymentTerm: string
  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
