import { BaseComponent } from '@abstract/BaseComponent';
import { Component, Input, OnInit } from '@angular/core';
import { AttachedFile } from '@wearewarp/types/data-model';
import { PodService } from '../../pod.service';
import { DialogService } from '@dialogs/dialog.service';
import { Utils } from '@services/utils';
import { JobV2 } from '../../interface';

@Component({
  selector: 'pc-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss', '../../style.scss']
})
export class DocumentComponent extends BaseComponent {
  @Input() bol: AttachedFile;
  @Input() loadTender: AttachedFile;
  constructor(private podService: PodService) {
    super();
  }

  ngOnInit(): void {
  }

  get label(){
    const listShipments = this.podService.getShipmentEntities();
    let totalShipment = listShipments.length;
    return `Documents - ${totalShipment} shipments`
  }

  get listShipmentTxt(){
    const listShipments = this.podService.getShipmentEntities();
    let arr: any = [];
    for (let shipment of listShipments) {
      arr.push(shipment.getCodeText());
    }
    return arr.join(', ');
  }

  copyAllShipmentId(){
    let job = this.podService.getpresentJob();
    let allShipmentId = this.getAllShipmentCodeAndWarpId(job)
    if(!allShipmentId) return;
    Utils.copyTextToClipboard(allShipmentId, e => {
      if (e) {
        this.showErr('Cannot copy text to clipboard');
      } else {
        this.showSuccess('All shipment Id has been copied successfully');
      }
    })
  }

  viewFile(file: AttachedFile){
    if(file){
      DialogService.preview([file], 0);
    }
  }

  getAllShipmentCodeAndWarpId(job: JobV2) {
    let arr = job?.shipments?.map(item => {
      const hasCode = !!item?.code;
      const hasWarpId = !!item?.warpId;
      if (hasCode && hasWarpId) {
        return `${item.code.replace('S-', '')} ( ${item.warpId} )`;
      } else if (hasCode && !hasWarpId) {
        return item.code.replace('S-', '');
      } else if (!hasCode && hasWarpId) {
        return item.warpId;
      }
      return '';
    });
    return arr?.join(', ');
  }

}
