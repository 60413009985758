<div class="container">
    <div id="{{file._id}}">
        <div *ngIf="!isPdf(file)" class="pod-item" (click)="viewPOD()">
            <img-lazy  class="attached-pod" width="48px" height="65px"  [src]="file.localUrl" alt=""></img-lazy>
        </div>
        <div *ngIf="isPdf(file)">
            <div class="attached-pod pdf overlay-container pod-item" (click)="viewPOD()">
                <canvas id="pod-{{file._id}}" width="48px" height="65px">
                </canvas>
            </div>
            <div *ngIf="isPdfMultiplePage" class="pdf-pages-preview">
                <div *ngFor="let image of pdfImages; let i = index" class="pdf-page-item bottom5" [ngClass]="{'isSelected': selectedPageIndex === i}">
                    <div class="page-info" (click)="onSelectPageIndex(i)">
                        <div *ngIf="!isConfirmedPage(i)">({{getPdfPageIndex(i)}}/{{pdfImages.length}})</div>
                        <div *ngIf="isConfirmedPage(i)"><i nz-icon nzType="check" nzTheme="outline" class="confirmed-text"></i></div>    
                    </div>
                    <span class="btn-view-page" (click)="onBtnViewPdfPage(i)">View</span>
                </div>    
            </div>
        </div>
    </div>

    <div *ngIf="!isConfirmed(file)" class="confirm-pod">
        <ng-container *ngFor="let shipment of shipmentEntities">
            <div class="shipment">
                <div class="flex-space-between">
                    <div class="code" (click)="onClickShipment(shipment)">{{shipment.getCodeText()}}</div>
                    <div class="flex gap20">
                        <ng-container *ngFor="let type of getTaskTypesOfShipment(shipment)">
                            <label nz-checkbox [(ngModel)]="taskChecked[getTaskIdForShipmentByType(shipment, type)]">{{getTaskTypelabel(type)}}</label>
                        </ng-container>
                    </div>
                </div>
                <div *ngIf="getTextactForShipment(shipment)" class="center-vertical text gap5">
                    <div>Found text:</div>
                    <div>{{getTextactForShipment(shipment)}}</div>
                </div>
                <div *ngIf="getConfidentForShipment(shipment)" class="center-vertical text gap5 top4">
                    <div>Confident:</div>
                    <div>{{getConfidentForShipment(shipment)}}</div>
                </div>
                <!-- <a class="top8 text-blur-1 underline" (click)="confirmRemoveShipment(shipment)">Remove</a> -->
            </div>
        </ng-container>
        <div class="flex-space-between flex-row-reverse top8">
            <button nz-button nzType="primary" (click)="confirmPod(true)" [disabled]="isDisableConfirm" [nzLoading]="isSubmitting">Confirm</button>
            <div *ngIf="!hasSelectedPdfPage">
                <ng-container *ngTemplateOutlet="groupBtn; context: {isLoadingIssue, countIssue}"></ng-container>
            </div>
        </div>
    </div>
    <div *ngIf="isConfirmed(file)" class="flex1">
        <div class="flex-space-between flex-row-reverse top8">
            <div class="center-vertical gap5">
                <div class="f12">
                    <div>{{formatDate(file.podConfirmed.when)}}</div>
                    <div>By <create-by-user [createBy]="file?.podConfirmed" [styleTextColor]="'#1802D0'"></create-by-user></div>
                </div>
                <button nz-button class="confirmed" id="{{id}}" (click)="unConfirmPOD()">
                    <span nz-icon [nzType]="iconBtnConfirmed" nzTheme="outline"></span>
                    {{textBtnConfirmed}}
                </button>
            </div>
            <div *ngIf="!hasSelectedPdfPage">
                <ng-container *ngTemplateOutlet="groupBtn; context: {isLoadingIssue, countIssue}"></ng-container>
            </div>
        </div>
    </div>
</div>
<ng-template #assignPodTemplate>
    <pod-confirmation-assign-pod [tasks]="allTasks" [shipments]="shipmentWithStop" [podItem]="this.file" [refreshData]="refreshData"></pod-confirmation-assign-pod>
</ng-template>
<ng-template #uploadWhenBy let-file="file">
    <div style="font-size: 12px; font-weight: 400; line-height: 16px; color: #A1A1AA;"> Uploaded {{formatDate(file?.insert?.when)}}, by <create-by-user [createBy]="file.insert" [styleFontWeight]="400"></create-by-user></div>
</ng-template>
<ng-template #podModalheader>
    <ng-container *ngTemplateOutlet="uploadWhenBy; context: {file: file}"></ng-container>
</ng-template>
<ng-template #groupBtn let-isLoadingIssue="isLoadingIssue" let-countIssue="countIssue">
    <div class="center-vertical gap20">
        <a class="link non_decoration" [nz-popover] nzPopoverTrigger="click" nzPopoverTitle="Assign POD" [nzPopoverContent]="assignPodTemplate" nzPopoverPlacement="bottom">
            <span nz-icon nzType="plus" nzTheme="outline"></span>
            Assign to shipment
        </a>
        <div class="issue" (click)="onBtnViewIssue()">
            <img src="/assets/img/pod_confirmation/issue.svg" alt="">
            <div class="text-blur-2 text">
                <span>Issue</span>
                (<span *ngIf="!isLoadingIssue">{{countIssue}}</span>
                <span *ngIf="isLoadingIssue" nz-icon nzType="loading" nzTheme="outline"></span>)
            </div>
        </div>
    </div>
</ng-template>