import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseFormItem } from '@app/admin/base/form-item';
import { QuickUploadPod } from '@app/admin/finance-v2/quick-upload-pod';
import { Const } from '@const/Const';
import { DateUtil } from '@services/date-utils';
import { Client } from '@wearewarp/types/data-model';

interface CountTab {
  needPodConfirm?: number,
  needPodUpload?: number,
  all?: number,
}

@Component({
  selector: '[pod-confirmation-list-filter]',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class PodConfirmationListFilter extends BaseFormItem {
  @Input() set countSum(value: CountTab) {
    if (typeof value.needPodConfirm == 'number') {
      this.filterTabs[0].total = value.needPodConfirm;
    }
    if (typeof value.needPodUpload == 'number') {
      this.filterTabs[1].total = value.needPodUpload;
    }
    if(typeof value.all == 'number'){
      this.filterTabs[2].total = value.all;
    }
  }
  filterTabs = [
    {
      key: 'need_pod_confirm',
      title: 'PODs confirm needed',
      total: 0,
    },
    {
      key: 'need_pod_upload',
      title: 'PODs upload needed',
      total: 0,
    },
    {
      key: 'all',
      title: 'All',
      total: 0,
    }
  ];
  filterTabsIndex = {need_pod_confirm: 0, need_pod_upload: 1, all: 2};
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFilterTabChanged: EventEmitter<number> = new EventEmitter<number>();
  public condition: any = {};
  public searchKeyword: string = null;
  public sort: any = undefined;
  public page: number = 1;
  protected formGroupDeclaration: FormGroupDeclaration = PodConfirmationListFilter.declaration;
  public paymentTerms: string[];
  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    this.paymentTerms = Const.paymentTermArray
    this.activatedRoute.queryParams.subscribe(p => {
      if (p.filter) {
        try {
          this.condition = JSON.parse(p.filter);
        } catch {
          this.condition = {};
        }
      }
      this.sort = p.sort || undefined;
      this.searchKeyword = p.search;
      this.page = p.page || 1;
      let tab = p.tab || this.filterTabs[0].key;
      this._selectedTabIndex = this.filterTabsIndex[tab] || 0;
      this.onFilterTabChanged.emit(this._selectedTabIndex);
    });
    this.onRefresh();
  }

  public static get declaration(): FormGroupDeclaration {
    return {
      searchKeyword: {label: 'Keyword', placeHolder: '', notAcceptEmpty: true},
      carrierId: {label: 'Carrier', notAcceptEmpty: true, placeHolder: 'Carrier'},
      clientId: {label: 'Client', notAcceptEmpty: true, placeHolder:'Customer'},
      paymentTerm: {label: 'Payment Term', notAcceptEmpty: true, placeHolder: `Carrier's payment term`}
    };
  }

  private _selectedTabIndex = 0;

  get selectedTabIndex() {
    return this._selectedTabIndex;
  }

  set selectedTabIndex(value) {
    if (this._selectedTabIndex == value) {
      return;
    }
    this.sort = undefined;
    this._selectedTabIndex = value;
    this.onFilterTabChanged.emit(value);
    this.page = 1;
    this.condition = {};
    this.formInput.reset();
    this.onRefresh();
  }

  ngOnInit(): void {
    this.model = Object.assign({}, this.condition, {search: this.searchKeyword});
    super.ngOnInit();
    this.fetchCarrierList();
    this.fetchClientList();
  }

  onChange(e, name) {
    this.page = 1
    if (e)
      this.condition[name] = e
    else
      delete this.condition[name]
    this.loadData()
  }

  public isFetchingCarrier = false;
  listCarriers = [];
  private fetchCarrierList() {
    let url = `${Const.APIURI_CARRIERS}?status=${Const.CarrierStatus.active}&limit=-1`;
    this.isFetchingCarrier = true;
    this.api.GET(url).subscribe(
      resp => {
        this.listCarriers = resp.data.list_data;
        this.isFetchingCarrier = false;
      }, err => {
        this.showErr(err);
        this.isFetchingCarrier = false;
      }
    );
  }

  public isFetchingClient = false;
  listClients = [];
  private fetchClientList() {
    let url = `${Const.APIURI_CLIENTS}?limit=-1`;
    this.isFetchingClient = true;
    this.api.GET(url).subscribe(
      resp => {
        this.listClients = resp.data.list_data;
        this.isFetchingClient = false;
      }, err => {
        this.showErr(err);
        this.isFetchingClient = false;
      }
    );
  }

  doSearch(s) {
    this.searchKeyword = s;
    this.page = 1;
    this.loadData();
  }

  onRefresh() {
    this.onReload.emit();
    if (this.sort) {
      this.sort = null;
    }
    this.loadData();
  }

  loadData() {
    let query = {page: this.page || 1, loaded: Date.now()};
    if (this.searchKeyword) {
      query['search'] = this.searchKeyword;
    }
    this.reformatConditionObject();
    query['tab'] = this.filterTabs[this._selectedTabIndex].key;
    query['filter'] = JSON.stringify(this.condition);
    if (this.sort) {
      query['sort'] = this.sort;
    }
    this.routeWithQueryUrl(query);
  }

  private reformatConditionObject() {
    if (this.condition.carrierId == null) {
      delete this.condition.carrierId;
    }
    if (this.condition.fromDate == null) {
      delete this.condition.fromDate;
    }
    if (this.condition.toDate == null) {
      delete this.condition.toDate;
    }
  }

  openQuickUploadPOD(){
    this.modalService.create({
      nzContent: QuickUploadPod,
      nzTitle: null,
      nzFooter: null,
      nzStyle:{
        top: '10px',
      },
      nzWidth: '100%',
    })
  }

  public getClientName(client: Client){
    return client?.name || 'N/A'
  }

}
