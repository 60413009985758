<div class="box-padding-16">
    <div class="flex-space-between">
        <div class="label">{{label}}</div>
        <a (click)="copyAllShipmentId()">Copy all shipment ID</a>
    </div>
    <div class="box-item">
        <div class="flex-space-between">
            <div class="flex-gap-5">
                <img src="/assets/svg/file.svg">
                <a (click)="viewFile(bol)">BOL</a>
            </div>
            <div class="list-shipment">{{listShipmentTxt}}</div>
        </div>
        
    </div>
    <div class="box-item">
        <div class="flex-gap-5">
            <img src="/assets/svg/file.svg">
            <a (click)="viewFile(loadTender)">LoadTender</a>
        </div>
    </div>
</div>
