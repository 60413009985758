<div class="dashboard-child-container no-padding list">
    <div class="list-header flex no-padding" style="flex-direction: column; width: 100%;background-color: unset">
      <div (onFilterTabChanged)="onFilterTabChanged($event)" pod-confirmation-list-filter [countSum]="countSum" style="width: 100%;"></div>
    </div>
    <div class="list-body">
      <div class="flex flex-direction-reverse">
        <div class="bottom20"><span class="text-red">Total: {{totalCount}} {{ totalCount > 1 ? 'items' : 'item'}}</span></div>
      </div>
      <nz-table #nzTable [nzData]="listData"
        [nzLoadingIndicator]="tplLoading" [nzLoading]="isLoading" [nzNoResult]="tplNoData"
        [nzTotal]="totalCount" [nzFrontPagination]="false"
        [nzShowPagination]="totalCount > listData.length"
        [nzPageSize]="limit" [nzSize]="'default'"
        nzBordered="true" nzSize="small"
        (nzPageIndexChange)="onDataListPageChanged($event)">
        <ng-template #tplNoData>
          <div *ngIf="!isLoading" class="nodata">
            <i nz-icon nzTheme="outline" nzType="search"></i>
            No data
          </div>
        </ng-template>
        <ng-template #tplLoading>
          <div class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
        </ng-template>
        <thead>
          <tr>
            <th>Route</th>
            <th [nzSortFn]="sortCompleteDate" >Route completed date</th>
            <th>Customer</th>
            <th>Carrier</th>
            <th>Payment term</th>
            <th >Action</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of nzTable.data; let i = index">
            <tr>
              <td>
                <div class="flex justtify-between">
                    <a  [routerLink]="[routeAdminDispatchList, item.route.id]" nz-tooltip
                    nzTooltipTitle="Go to dispatch" style="font-size: 13px;" target="_blank">
                    <code>{{item.route.code}}</code>
                    </a>
                    <i [routerLink]="[routeAdminDispatchList, item.route.id]" nz-tooltip
                    nzTooltipTitle="Go to dispatch" nz-icon nzType="rocket" nzTheme="outline" style="margin-left: 5px;"></i>
                </div>
              </td>
              <td>{{getCompletedDate(item)}}</td>
              <td>
                  <a class="link" *ngFor="let customer of getCustomers(item); let customerIndex=index" [routerLink]="[routeAdminClientList, customer.id]" target="_blank"><span *ngIf="customerIndex != 0">, </span>{{customer.name}}</a>
              </td>
              <td>
                <a class="link" [routerLink]="[routeAdminCarrierList, item.route?.carrier?.id]" target="_blank">{{getCarrierNameTxt(item)}}</a>
              </td>
              <td>
                {{getPaymentTermName(item.finAccount.paymentTerm)}}
              </td>
              
              <td>
                <a [href]="getRouterLinkDetail(item)" target="_blank" nz-tooltip
                nzTooltipTitle="Go to POD Confirmation detail" class="link-item"><span nz-icon nzType="eye" nzTheme="outline"></span> {{actionText}}</a>
                <!-- <div *ngIf="shouldShowPodMatchingBtn(item)">
                  <button nz-button nzTheme="outline" (click)="onBtnAnalyzePodMatchWithShipment(item)" [disabled]="isPodAnalyzing(item.route.id)">
                    POD matching
                    <i *ngIf="isPodAnalyzing(item.route.id)" nz-icon nzType="loading" nzTheme="outline"></i>
                  </button>  
                </div> -->
              </td>
            </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
  </div>