import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ImageLocal } from "./pdfFilePod.interface";
import { ApiService } from "@services/api.service";
import { Const } from "@const/Const";
import { Shipment } from "@wearewarp/types/data-model";
import * as pdfjsLib from "pdfjs-dist"; //sử dụng để đọc file PDF
import { PODLocalFile } from "../../pod.service";
import { AttachedFileUtil } from "@services/attached-file-util";
import to from "await-to-js";
pdfjsLib.GlobalWorkerOptions.workerSrc =
  "assets/mozilla/pdfjs-2.13.216/pdf.worker.min.js";


/**
 * Xử lý cho file pdf
 */
@Injectable()
export class PdfFilePodService {
  public images: BehaviorSubject<ImageLocal[]> = new BehaviorSubject([]);
  public imageSelectedIndex: BehaviorSubject<number> = new BehaviorSubject(null);
  private readonly defaultImageSize:any = {
    "height": 1920 * 2,
    "width": 1080 * 2
  }
  constructor(private api: ApiService) {
  }

  // Kiểm tra là file pdf trước khi set
  async setPdfFilePod(file: PODLocalFile) {
    if (!AttachedFileUtil.isPdf(file)) return;
    let url = `${Const.APIURI_DOWNLOAD}/${file._id}`;
    const [err, resp] = await to(this.api.download(url).toPromise());
    if (err) {
      console.log(err);
      return;
    }
    const blob = new Blob([resp], { type: file.type });
    const images = await this.convertPdfToImages(new File([blob], file.name, { type: file.type }));
    let fileNameInfo = this.extractFileName(file.name);
    for (let index in images) {
      const image = images[index];
      let fileName = `${fileNameInfo.name}_${index}.${fileNameInfo.ext}`;
      if (images.length == 1) fileName = `${fileNameInfo.name}.${fileNameInfo.ext}`;
      this.addImage({
        blob: image,
        name: fileName,
        url: URL.createObjectURL(image),
      });
    }
  }

  getSelectedImage() {
    const index = this.imageSelectedIndex.getValue();
    if (index === null) return null;
    return this.images.getValue()[index];
  }

  async setSelectedIndex(index: number) {
    this.imageSelectedIndex.next(index);
  }

  getSelectedIndex() {
    return this.imageSelectedIndex.getValue();
  }

  getImageByIndex(index: number) {
    return this.images.getValue()[index] || null;
  }

  addImage(image: ImageLocal) {
    this.images.next([...this.images.getValue(), image]);
  }

  clearImages() {
    this.images.getValue().map(image => {
      URL.revokeObjectURL(image.url)
    });
    this.images.next([]);
  }

  //PDF Utils
  public async convertPdfToImages(file: File) {
    const pdfDoc = await pdfjsLib.getDocument(URL.createObjectURL(file)).promise;
    let promises = [];
    for (let i = 1; i <= pdfDoc.numPages; i++) {
      promises.push(this.getBlobFromPdfPage(pdfDoc, i))
    };
    return await Promise.all(promises);
  }

  public async uploadImageForListTasksOfStop(image: ImageLocal, taskIds: any[]) {
    // const { jobId, shipmentDropoffTask } = await this.getDataBeforeUploadPOD(shipment);
    if (taskIds.length === 0) return;
    let fileInfo = {
      blob: null,
      name: null,
    }

    fileInfo.blob = image.blob;
    fileInfo.name = image.name;
    //add POD
    let apiUrl = `${Const.APIV2(Const.APIURI_TASKS)}/uploadPodForListTasksOfStop`;
    let formData = new FormData();
    const jsonData = {
      'taskIds': taskIds,
    }
    formData.append("params", JSON.stringify(jsonData));
    formData.append("uploadPOD", new File([fileInfo.blob], fileInfo.name || 'image.jpg'));
    const result = await this.api.postFormData(apiUrl, formData).toPromise();
   
    await this.confirmPOD(result.data?.uploadInfo?.podItem, taskIds);

    image.isConfirmed = true;
    this.images.next(this.images.getValue());
    return result;
  }

  async confirmPOD(podFileId: string, taskIds: string[]) {
    let params = {
      taskIds: taskIds,
      "uploadPodFileId": podFileId
    };
    let url = `${Const.APIV2(Const.APIURI_TASKS)}/confirmPodForListTasksOfStop`;
    const result = await this.api.POST(url, params).toPromise();
    return result;
  }

  private async getBlobFromPdfPage(pdfDoc, pageNumber): Promise<Blob> {
    const page = await pdfDoc.getPage(pageNumber);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // select scale for image to fit
    const unscaledViewport = page.getViewport({scale: 1});
    const scale = Math.min((this.defaultImageSize.width / unscaledViewport.width), (this.defaultImageSize.height / unscaledViewport.height));
    const viewport = page.getViewport({ scale });
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    await page.render({
      canvasContext: context,
      viewport: viewport
    }).promise;

    const base64 = canvas.toDataURL('image/jpeg');
    const blob = this.base64toBlob(base64.split(',')[1], 'image/jpeg');
    return blob;
  }

  private base64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  private extractFileName(fileName: string) {
    let ext = fileName.split('.').pop();
    const name = fileName.split('.').slice(0, -1).join('.');
    if (ext.toLowerCase() === "pdf") ext = "jpg";
    return {
      name,
      ext
    }
  }

}