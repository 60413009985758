import { BaseComponent } from '@abstract/BaseComponent';
import { Component, OnInit } from '@angular/core';
import { PodService } from '../pod.service';
import { ActivatedRoute } from '@angular/router';
import { Job, Stop } from '@wearewarp/types/data-model';
import StopEntity from '../Entity/StopEntity';
import { JobV2 } from '../interface';
import { DateUtil } from '@services/date-utils';
import { Const } from '@const/Const';
import { environment } from '@env/environment';

@Component({
  selector: 'pod-confirmation-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class PodConfirmationDetail extends BaseComponent {

  public job: JobV2;
  public isLoading: boolean = true;;
  public loading$;
  public job$;
  public stopEntities: StopEntity[];
  public onProgress: boolean = false;
  constructor(private podService: PodService, protected activatedRoute: ActivatedRoute) { 
    super();
    this.loading$ = this.podService.loading;
    this.job$ = this.podService.job;
  }

  ngOnInit(): void {
    this.autoShrinkSideBar()
    this.subscription.add(
      this.job$.subscribe((job: JobV2 | null) => {
        if(job) {
          this.job = job;
          this.stopEntities = this.podService.getStops();
        }
      })
    );
    this.subscription.add(
      this.loading$.subscribe((data: boolean) => {
        this.isLoading = data;
      })
    )
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    const id= this.getIdForDetailComponent(this.activatedRoute);
    let runPODMatching = this.activatedRoute?.snapshot?.queryParams['runPODMatching'];
    if(runPODMatching){
      let url = `${environment.backendUrl}/v1/textract/suggest-pod-match-with-shipment`;
      let params = { jobId: id };
      this.onProgress = true;
      this.api.POST(url, params).subscribe(
        resp => {
          this.onProgress = false;
          this.podService.fetchRoute(id);
        },
        err => {
          this.onProgress = false;
          this.podService.fetchRoute(id);
        }
      )
    }else{
      this.podService.fetchRoute(id);
    }
  }

  // private buildDisplayInfo(){
  //   const displayInfo = {
  //     listStop: this.getListStops()
  //   };
  //   this.displayInfo = displayInfo;
  // }

  // public getListStops(){
  //   const stops = this.podService.getStops();
  //   if(!stops.length) return [];
  //   const displayStops = stops.map((stop: StopEntity, index) => {
  //     let pods = this.podService.getPodByStop(stop.getId());
  //     let needConfirm = false;
  //     for(let pod of pods){
  //       if(!pod?.podConfirmed?.when){
  //         needConfirm = true;
  //         break;
  //       }
  //     }
  //     return {
  //       id: stop?.getId(),
  //       type: stop?.getType(),
  //       index: index + 1,
  //       needConfirm: needConfirm
  //     }
  //   })
  //   return displayStops;
  // }

  // public isDisableBtnMarkAsConfirmedPOD(){
  //   return !(this.job?.isNeedConfirm == false)
  // }


  // public getMarkAsConfirmedWhenBy(type: string){
  //   let key;
  //   switch(type){
  //     case 'POD': key = 'markAsPODConfirmedWhenBy'; break;
  //     case 'Rate': key = 'markAsRateConfirmedWhenBy'; break;
  //   }
  //   const whenBy = this.job[key];
  //   if(!whenBy) return `${type} confirmed`;
  //   const { when, by} = whenBy;
  //   let name;
  //   if(by?.name) name = by?.name;
  //   else if(by?.fullName) name = by?.fullName;
  //   else if(by?.firstName || by?.lastName) name= by?.firstName + ' ' + by?.lastName;
  //   if(name && when) return `${type} confirmed by ${name} at ${DateUtil.formatDate(when, Const.FORMAT_GUI_DATE)}`;
  //   else if(name) return `${type} confirmed by ${name}`;
  //   else return `${type} confirmed at ${DateUtil.formatDate(when, Const.FORMAT_GUI_DATE)}`
  // }

  // onMarkAsConfirmedPOD(){
  //   if(!this.job?.jobConfirmationId) return;
  //   const url = `${Const.APIURI_POD_CONFIRMATION_V2('job_confirmation')}/${this.job?.jobConfirmationId}`;
  //   let data: any = {
  //     isNeedPODConfirm: false
  //   }
  //   this.onProgress = true;
  //   this.api.POST(url, data).subscribe({
  //     next: resp => {
  //       this.onProgress = false;
  //       this.showSuccess("Mark as POD confirmed successfully.");
  //       this.refreshData();
  //     },
  //     error: err => {
  //       this.onProgress = false;
  //       this.showErr(err);
  //     }
  //   })
  // }

  // onMarkAsConfirmedRate(){
  //   if(!this.job?.jobConfirmationId) return;
  //   const url = `${Const.APIURI_POD_CONFIRMATION_V2('job_confirmation')}/${this.job?.jobConfirmationId}`;
  //   let data: any = {
  //     isNeedRateConfirm: false
  //   }
  //   this.onProgress = true;
  //   this.api.POST(url, data).subscribe({
  //     next: resp => {
  //       this.onProgress = false;
  //       this.showSuccess("Mark as Rate confirmed successfully.");
  //       this.refreshData();
  //     },
  //     error: err => {
  //       this.onProgress = false;
  //       this.showErr(err);
  //     }
  //   })
  // }
  
  ngOnDestroy(){
    this.podService.clearOldData()
  }

  // private refreshData(){
  //   let pod = this.podService.getSelectedPod();
  //   if(pod?._id){
  //     this.podService.fetchRoute(this.job?.id, pod?.stopId, pod._id, pod?.taskId)
  //   }else{
  //     this.podService.fetchRoute(this.job?.id)
  //   }
  // }
}
