import { Component, Input, OnInit } from '@angular/core';
import { StringUUID } from '@wearewarp/types';
import { Const } from '@wearewarp/universal-libs';
import { PodService } from '../../pod.service';
import { BaseComponent } from '@abstract/BaseComponent';
import StopEntity from '../../Entity/StopEntity';
interface DisplayStop{
  id: StringUUID,
  type: string,
  index: number,
  needConfirm: boolean
}
@Component({
  selector: '[pod-confirmation-stop-list]',
  templateUrl: './stop-list.component.html',
  styleUrls: ['./stop-list.component.scss']
})
export class PodConfirmationStopList  extends BaseComponent{
  @Input() stopList: StopEntity[] = [];
  public stopIdArr: string[] = [];
  constructor(private podService: PodService) {
    super();
   }

  ngOnChanges(){
    if(this.stopList && !this.stopIdArr.length){
      this.stopIdArr = this.stopList.map(it => it.getId());
    }
  }

  public getStopEntityById(id: string){
    return this.stopList.find(it => it.getId() === id)
  }



}
