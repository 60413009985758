import { Component, Input, OnInit } from '@angular/core';
import { BaseDialog } from '@dialogs/base-dlg';
import { PODLocalFile } from '../../pod.service';

@Component({
  selector: 'pc-view-pod',
  templateUrl: './view-pod.component.html',
  styleUrls: ['./view-pod.component.scss']
})
export class ViewPodComponent extends BaseDialog {
  @Input() file: PODLocalFile
  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
