<div class="box-padding-16">
    <div class="label">Recipent</div>
    <div class="box-item">
        <div class="sub-label">Carrier</div>
        <div><a style="color: #191A23;" [routerLink]="[routeAdminCarrierList,carrier?.id]" target="_blank">{{carrierName}}</a> (MC: {{mc}})</div>
        <div *ngFor="let item of contactInfos">{{item}}</div>
    </div>
    <div class="box-item">
        <div class="sub-label">Driver</div>
        <div><a style="color: #191A23;" [routerLink]="[routeAdminDriverList,driver?.id]" target="_blank">{{driverName}}</a></div>
        <div>{{driverPhone}}</div>
    </div>
</div>
