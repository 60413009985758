import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgxViewerDirective } from 'ngx-viewer';

@Component({
  selector: '[app-ngx-viewer]',
  templateUrl: './ngx-viewer.component.html',
  styleUrls: ['./ngx-viewer.component.scss']
})
export class NgxViewerComponent {
  @ViewChild(NgxViewerDirective) ngxViewerElement: NgxViewerDirective;
  @Input() set src(value){
    this._value = value;
    this.fetchSrc()
  }
  @Input() nextFn : Function;
  @Input() backFn : Function;
  private _value;
  public fileUrl: string;
  public imageViewOptions: any = {
    backdrop:true,
    navbar: false ,
    inline: true,
    title:false,
    toolbar: {
      prev: () => {
        this.backFn()
      },
      rotateLeft: true,
      rotateRight: true,
      next: () => {
        this.nextFn()
      }
      
    }
  }
  constructor() { }
  async fetchSrc() {
    if(typeof this._value == 'string') {
      this.fileUrl = this._value;
      this.reRender();
      return;
    }
    this.fileUrl = await this._value();
    this.reRender()
  }
  public reRender(){
    setTimeout(() => {
      this.ngxViewerElement.instance.update();
    },1)
  }

}
