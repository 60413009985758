<div class="task">
    <div class="info">
        <div class="flex" style="justify-content: space-between; width: 100%;">
           <div class="flex gap10">
                <div style="font-weight: 600;">
                    <a [routerLink]="displayInfo.routerLink" target="_blank" style="color: #191A23;">{{displayInfo.shipmentWarpIdText}}</a>
                    <a><span nz-icon nzType="copy" nzTheme="outline" 
                        (click)="copyTextNew(displayInfo.shipmentWarpId)" 
                        nz-tooltip nzTooltipTitle="Copy NEW WARPID"
                        style="cursor: pointer; margin-left: 10px;"></span></a> 
                    <a><span nz-icon nzType="copy" nzTheme="outline" 
                        (click)="copyTextOld(displayInfo.oldWarpId)" 
                        nz-tooltip nzTooltipTitle="Copy OLD WARPID"
                        style="cursor: pointer; margin-left: 10px;"></span></a> 
                </div>
                <button class="task-status {{ 'task-status-' + displayInfo.cssClassStatus }}">{{displayInfo.status}}</button>
            </div>
            <a class="center-vertical gap5" [ngClass]="{ 'link': !isPodsHighlight}" (click)="onClickPods()">
                <span>{{displayInfo.pods.length}} PODs</span>
                <nz-badge *ngIf="getPODNeedConfirmCount()" nzStandalone [nzCount]="getPODNeedConfirmCount()"></nz-badge>
            </a>
        </div>
        <!-- <div>
            <span class="label">Customer: </span> 
            <span><a style="color: #191A23;" [routerLink]="[routeAdminClientList,displayInfo.clientId]" target="_blank">{{displayInfo.client}}</a></span>
        </div> -->
        <div class="refNum">
            <span class="label">Order ref no: </span>
            <span *ngIf="!hasOrderRef()">N/A</span>
            <ng-container *ngIf="hasOrderRef()" >
                <nz-tag class="ref-num" *ngFor="let ref of displayInfo.orderRefNums" [nzColor]="'rgba(51, 65, 85, 0.12)'" 
                [nzTooltipTitle]="ref" nzTooltipPlacement="bottom" nz-tooltip>{{ ref }}</nz-tag>
            </ng-container>
        </div>
        <div class="left-5">
            <nz-timeline>
                <nz-timeline-item [nzDot]="pickup">{{displayInfo.pickupAddr}}</nz-timeline-item>
                <nz-timeline-item [nzDot]="dropoff">{{displayInfo.dropoffAddr}}</nz-timeline-item>
            </nz-timeline>
        </div>
    </div>
    <!-- <div class="pods" list-pod-pod-confirmation [pods]="displayInfo.pods" [taskId]="displayInfo.taskId" [stop]="stop"></div> -->
</div>
<ng-template #pickup>
    <div 
        style=" display: flex;
                width: 20px;
                height: 20px;
                padding: 3.333px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 8.333px;
                border-radius: 83.333px;
                border: 1px solid #8D8D8D;
                background: #FFF;"
    >
        <span style="color:#1802D0; font-size: 10px; font-weight: 500;">PU</span>
    </div>
</ng-template>
<ng-template #dropoff>
    <div 
        style=" display: flex;
                width: 20px;
                height: 20px;
                padding: 3.333px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 8.333px;
                border-radius: 83.333px;
                border: 1px solid #8D8D8D;
                background: #FFF;"
    >
        <span style="color:#1802D0; font-size: 10px; font-weight: 500;">DO</span>
    </div>
</ng-template>