import { Component, Input, OnInit } from '@angular/core';
import { PODLocalFile, PodService } from '../../pod.service';
import { Const } from '@wearewarp/universal-libs';
import { ClientMeta } from '@wearewarp/types/data-model';
import { BaseComponent } from '@abstract/BaseComponent';
import { Utils } from '@services/utils';
import StopEntity from '../../Entity/StopEntity';
import { TaskStatus } from '@wearewarp/types';
interface TaskInfo{
  taskId: string,
  oldWarpId: string,
  status: TaskStatus,
  shipmentWarpIdText: string,
  shipmentWarpId: string,
  refNums: string[],
  pickupAddressText: string,
  pickupLocation: string,
  dropoffAddressText?: string,
  dropoffLocation?: string,
  pods: PODLocalFile[],
  orderRefNums: string[],
  shipmentTransitType: string,
  client: ClientMeta,
  routerLink: string
}
@Component({
  selector: '[task-pod-confirmation]',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss','../../style.scss']
})
export class TaskPodConfirmation extends BaseComponent{
  @Input() set taskInfo(values: TaskInfo){
    if(!values) return;
    this.buildDisplayInfo(values)
  };
  @Input() stop: StopEntity;
  public displayInfo: any;
  public isPodsHighlight: boolean = false;
  constructor(private podService: PodService) { super(); }


  ngOnInit(): void {
    this.subscription.add(
      this.podService.podsChange.subscribe(() => {
        let selectedStop = this.podService.getSelectedStop();
        let selectedTask = this.podService.getSelectedTask();
        this.isPodsHighlight = selectedStop.getId() == this.stop.getId() && selectedTask?.getId() == this.displayInfo.taskId;
      })
    )
  }

  private buildDisplayInfo(taskInfo: TaskInfo){
    let displayInfo: any = {
      taskId: taskInfo.taskId,
      shipmentWarpIdText: taskInfo.shipmentWarpIdText,
      shipmentWarpId: taskInfo.shipmentWarpId,
      pickupAddr: this.getPickupAddr(taskInfo),
      dropoffAddr: this.getDropoffAddr(taskInfo),
      refNums: taskInfo.refNums || [],
      pods: taskInfo.pods || [],
      orderRefNums: taskInfo.orderRefNums,
      shipmentTransitType: taskInfo.shipmentTransitType,
      client: taskInfo.client?.name || 'N/A',
      clientId: taskInfo.client?.id,
      routerLink: taskInfo.routerLink,
      oldWarpId: taskInfo.oldWarpId,
      cssClassStatus: taskInfo.status,
      status: Utils.capitalizeFirstLetter(taskInfo.status)
    };
    this.displayInfo = displayInfo;
  }

  getPickupAddr(taskInfo){
    if(taskInfo?.pickupLocation && taskInfo?.pickupAddressText) return `${taskInfo.pickupLocation}, ${taskInfo.pickupAddressText}`;
    else if(taskInfo?.pickupLocation) return taskInfo.pickupLocation;
    else if(taskInfo?.pickupAddressText) return taskInfo.pickupAddressText;
    return 'N/A'
  }

  getDropoffAddr(taskInfo){
    if(taskInfo?.dropoffLocation && taskInfo?.dropoffAddressText) return `${taskInfo.dropoffLocation}, ${taskInfo.dropoffAddressText}`;
    else if(taskInfo?.dropoffLocation) return taskInfo.dropoffLocation;
    else if(taskInfo?.dropoffAddressText) return taskInfo.dropoffAddressText;
    return 'N/A'
  }

  isShowRef(): boolean{
    return this.displayInfo?.refNums.length > 0;
  }
  isShowOrderRef(){
    return this.displayInfo?.shipmentTransitType == Const.ShipmentTransitType.leg;
  }
  hasOrderRef(){
    return this.displayInfo.orderRefNums.length > 0;
  }
  public copyTextNew(text){
    if(!text) return;
    Utils.copyTextToClipboard(text, e => {
      if (e) {
        this.showErr('Cannot copy text to clipboard');
      } else {
        this.showSuccess('New WARP ID copied');
      }
    })
  }

  public copyTextOld(text) {
    if(!text) return;
    Utils.copyTextToClipboard(text, e => {
      if (e) {
        this.showErr('Cannot copy text to clipboard');
      } else {
        this.showSuccess('Old WARP ID copied');
      }
    })
  }

  public getPODNeedConfirmCount(){
    return this.displayInfo?.pods?.filter(pod => !pod?.podConfirmed?.when).length;
  }

  public onClickPods(){
    this.podService.setSelectedStopAndTask(this.stop.getId(), this.displayInfo.taskId)
  }
}
