<div *ngIf="hasData()" style="width: 100%;">
    <div class="stop">
        <div class="stop-title" (click)="toggleCollapse()">
            <div class="flex align-center">
                <div>
                    <i *ngIf="!expanded" class="btn-icon icon arrow_drop_down right5"></i>
                    <i *ngIf="expanded" class="btn-icon icon arrow_drop_up right5"></i>
                </div>
                <div>Stop {{index}} - {{getTypeText(stop)}}</div>
            </div>
            <div class="flex gap5">
                <button class="tag {{ 'stop-status-' + stop.getStatus()}}">{{getStatusText(stop)}}</button>
                <a class="center-vertical gap5" [ngClass]="{ 'link': !isPodsHighlight}" (click)="onClickPods($event)">
                    <span *ngIf="displayInfo?.podStopLevel?.length">{{displayInfo.podStopLevel?.length}} PODs</span>
                    <span *ngIf="!displayInfo?.podStopLevel?.length">View POD</span>
                    <nz-badge *ngIf="PODNeedConfirmRemaining()" nzStandalone [nzCount]="PODNeedConfirmRemaining()"></nz-badge>
                </a>
            </div>
        </div>
        <div collapse-animation [expanded]="expanded">
            <div class="stop-level" id="stop-{{displayInfo.id}}">
                <div class="info">
                    <div class="address">
                        <span>{{displayInfo.locationName}}, {{displayInfo.addr}}</span>
                    </div>
                    <div>
                        <span class="label">Arrived: </span> <span>{{displayInfo.actualArrived}}</span>
                    </div>
                    <div>
                        <span class="label">{{displayInfo.isScheduled ? 'Appointment' : 'Scheduled'}}: </span> 
                        <span>{{displayInfo.appointment || 'N/A'}} {{displayInfo.appointment ? displayInfo.timezone : ''}}</span>
                    </div>
                </div>
                <!-- <div *ngIf="displayInfo?.tasks?.length > 1" class="pods" list-pod-pod-confirmation [pods]="displayInfo.podStopLevel" [stop]="stop"></div> -->
                <div>{{displayInfo.tasks.length}} shipment{{displayInfo?.tasks?.length > 1 ? 's':''}}</div>
            </div>
            <div class="task-container bottom-border">
                <ng-container *ngFor="let task of displayInfo.tasks" >
                    <div class="task-level" task-pod-confirmation [taskInfo]="task" id="task-{{task?.taskId}}" [stop]="stop"></div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!hasData()">
    <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 10, width: ['100%', '100%', '100%', '100%','100%','100%', '100%', '100%','100%','100%'] }"></nz-skeleton>
</div>
