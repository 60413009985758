<div>
    <div class="center-vertical bottom10">
        <button nz-button nzType="default" (click)="closePopup()">
            Close
          </button>
        <h3 class="title">Note & History</h3>
    </div>
    <div>
        <nz-tabset>
            <nz-tab nzTitle="Notes">
              <note-list [jobId]="jobId"></note-list>
            </nz-tab>
            <nz-tab nzTitle="History">
              <history-list [objectId]="jobId" entity="jobs" [isCustomTimeline]="true"></history-list>
            </nz-tab>
        </nz-tabset>
    </div>
</div>
