<div class="pod-container">
    <div class="image" *ngFor="let item of pods">
        <nz-badge  [nzCount]="getTemplateType(item)" nzOffset="[200,200]" [nzStyle]="{marginLeft: '48px', possiton:'relative'}">
            <div *ngIf="!isPdf(item)" class="pod-item">
                    <img-lazy  class="attached-pod" width="48px" height="65px"  [src]="item.localUrl" alt=""></img-lazy>
            </div>
        
            <div *ngIf="isPdf(item)">
              <div class="attached-pod pdf overlay-container pod-item">
                <canvas *ngIf="item?.taskId" id="task-{{item.taskId}}-pod-{{getIndexForPod(item._id)}}-{{load}}" width="48px" height="65px">
                </canvas>
                <canvas *ngIf="!item?.taskId" id="stop-{{item.stopId}}-pod-{{getIndexForPod(item._id)}}-{{load}}" width="48px" height="65px">
                </canvas>
              </div>
            </div>
        </nz-badge>
    </div>
    <div *ngIf="!isUploading && enableAddPOD" class="image upload-pod" (click)="onClickAddPod($event)"><span nz-icon nzType="plus" nzTheme="outline"></span></div>
    <div *ngIf="isUploading" class="uploading"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
    <input #inputFile type="file" hidden accept=".pdf,.png,.jpg,.jpeg" (change)="onFileSelected(inputFile)"
      id="stop-{{stop?.getId()}}-uploadRoutePODInput-{{taskId}}-{{load}}">
</div>
<ng-template #needConfirm>
    <span nz-icon style="font-size: 16px; position: absolute; bottom: -5px; right: -5px;" nzType="clock-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#0A6CF5'"></span>
</ng-template>
<ng-template #hasBeenConfirmed>
    <span nz-icon style="font-size: 16px; position: absolute;  bottom: -5px; right: -5px;" nzType="check-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#00B227'"></span>
</ng-template>