import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { FormInputCostModule } from '../base/form-input-cost/module';
import { SharedModule } from '../shared/shared.module';
import { UiCommonModule } from '../components/common/module';
import { PodService } from './pod.service';
import { DrawerModule } from '@app/drawers/drawer.module';
import { DispatchModule } from '../dispatch/module';
import { CustomIconModule } from '../components/icon/icon.module';
import { NgxViewerModule } from 'ngx-viewer';
import { PodConfirmationRoutingModule } from './pod-confirmation.route';
import { PdfViewerModule } from '@libs/pdf-viewer/pdf-viewer.module';
import { ShipmentStructureModule } from '../shipments/shipment-structure/module';
import { FinanceModule } from '../finance-v2/module';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { ModuleNotes } from '../components/notes/module';
import { ImageLazy } from './components-v2/img-lazy';
import { SafeUrlPipe } from './pipes/safeUrl.pipe';
import { ListJobItemModule } from '../dispatch/components/list-job-item/module';
import { PodConfirmationList } from './route-list/route-list.component';
import { PodConfirmationListFilter } from './route-list/filter/filter.component';
import { PodConfirmationDetail } from './detail/detail.component';
import { podConfirmationHeader } from './components-v2/header/header.component';
import { PodConfirmationStopList } from './components-v2/stop-list/stop-list.component';
import { PodConfirmationStopDetail } from './components-v2/stop-detail/stop-detail.component';
import { ListPodPodConfirmation } from './components-v2/list-pod/list-pod.component';
import { TaskPodConfirmation } from './components-v2/task/task.component';
import { PodDetail } from './components-v2/pod-detail/pod-detail.component';
import { NgxViewerComponent } from './components-v2/ngx-viewer/ngx-viewer.component';
import { RouteAssignPodTasks } from './components-v2/update-pod-tasks';
import { SendEmailRequestPod } from './components-v2/send-email-request-pod/send-email-request-pod.component';
import { PdfPod } from './components-v2/pdf-pod/pdf-pod.component';
import { CreateByUser } from './components-v2/create-by-user';
import { Issue } from './components-v2/issue/issue.component';
import { NoteAndHistory } from './components-v2/note-and-history/note-and-history.component';
import { RateConfirmation } from './components-v2/rate/rate.component';
import { DocumentComponent } from './components-v2/document/document.component';
import { PayableAmountComponent } from './components-v2/payable-amount/payable-amount.component';
import { PaymentComponent } from './components-v2/payment/payment.component';
import { RecipentComponent } from './components-v2/recipent/recipent.component';
import { ModuleDisplayFinJobCost } from '../fin/components/fin-job-cost/module';
import { PodItemComponent } from './components-v2/pod-item/pod-item.component';
import { ViewPodComponent } from './components-v2/view-pod/view-pod.component';
import { ViewImageOnlyComponent } from './components-v2/view-pod/view-image-only';
import { PanZoomImagePreviewModule } from '../components/panzoom-image-preview/module';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PodConfirmationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NzTableModule,
    NzTabsModule,
    NzEmptyModule,
    NzPaginationModule,
    NzSelectModule,
    NzButtonModule,
    NzInputModule,
    NzIconModule,
    NzFormModule,
    NzCollapseModule,
    NzCheckboxModule,
    NzRadioModule,
    NzDividerModule,
    NzDatePickerModule,
    SearchBoxModule,
    SelectBySearchingModule,
    FormInputCostModule,
    NzToolTipModule,
    NzTypographyModule,
    NzPopoverModule,
    SharedModule,
    UiCommonModule,
    NzBadgeModule,
    DrawerModule,
    CustomIconModule,
    NgxViewerModule,
    DispatchModule,
    PdfViewerModule,
    ShipmentStructureModule,
    FinanceModule,
    ModuleNotes,
    ListJobItemModule,
    ModuleDisplayFinJobCost,
    PanZoomImagePreviewModule,
  ],
  declarations: [
    ImageLazy,
    SafeUrlPipe,
    CreateByUser,
// pod-confirmation v2
    PodConfirmationListFilter,
    PodConfirmationList,
    PodConfirmationDetail,
    podConfirmationHeader,
    PodConfirmationStopList,
    PodConfirmationStopDetail,
    ListPodPodConfirmation,
    TaskPodConfirmation,
    PodDetail,
    NgxViewerComponent,
    RouteAssignPodTasks,
    SendEmailRequestPod,
    PdfPod,
    Issue,
    NoteAndHistory,
    RateConfirmation,
    DocumentComponent,
    PayableAmountComponent,
    RecipentComponent,
    PaymentComponent,
    PodItemComponent,
    ViewPodComponent,
    ViewImageOnlyComponent,
  ],
  exports: [
  ],
  providers: [
    PodService
  ]
})
export class PodConfirmationModule { }
