<div class="pod-container">
    <ng-container *ngIf="!isLoading">
        <div class="header-info">
            <ng-container *ngIf="!isInSelectMode">
                <div class="text-blur-1" *ngIf="isStopLevel">{{stopInfo}}</div>
                <div class="text-blur-1" *ngIf="isTaskLevel">{{taskInfo}}</div>
            </ng-container>
            <ng-container *ngIf="isInSelectMode">
                <div class="text-blur-1">
                    {{selectedPodsText}}
                </div>
            </ng-container>
            <div class="center-vertical gap20">
                <ng-container *ngIf="isGroupBtnAvailable()">
                    <div class="center-vertical gap3 pointer" (click)="deleteAll()">
                        <img src="/assets/img/pod_confirmation/delete.svg" alt="">
                        <span *ngIf="isDeleting" nz-icon nzType="loading" nzTheme="outline"></span>
                    </div>
                    <div class="center-vertical gap3 pointer" (click)="downloadAll()">
                        <img src="/assets/img/pod_confirmation/download.svg" alt="">
                    </div>
                    <button nz-button nzType="primary" [nzLoading]="isSubmitting" (click)="onBtnConfirmAll()">Confirm all</button>
                </ng-container>
                <ng-container *ngIf="!isGroupBtnAvailable()">
                    <button nz-button nzBlock [nzLoading]="isUploading" (click)="onClickAddPod($event)"><span nz-icon nzType="plus" nzTheme="outline"></span> Add file</button>
                    <input multiple #inputFile type="file" hidden accept=".pdf,.png,.jpg,.jpeg" (change)="onFileSelected(inputFile)"
                        id="uploadRoutePODInput">
                    <div>
                        <img src="/assets/img/pod_confirmation/disabled_delete.svg" alt="">
                    </div>
                    <div>
                        <img src="/assets/img/pod_confirmation/disabled_download.svg" alt="">
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="view-pod">
           <div *ngFor="let file of arrayPOD; trackBy: trackPod" style="position: relative;">
                <pc-pod-item [file]="file" [isDisableConfirm]="isInSelectMode" [isSelected]="checkboxModel[file._id]" (viewInIted)="attachEventSelectPod($event)"></pc-pod-item>
                <label id="checkbox-{{file._id}}" *ngIf="shouldShowSelectCheckBox[file._id]" class="checkbox" nz-checkbox [(ngModel)]="checkboxModel[file._id]" (ngModelChange)="onSelectPod($event)"></label>
           </div>
           
        </div>
    </ng-container>
    <ng-container *ngIf="isLoading">
        <div>
            <div  class="nodata" ><i nz-icon nzType="loading" nzTheme="outline"></i></div>
        </div>
    </ng-container>
</div>
