import { BaseComponent } from '@abstract/BaseComponent';
import { Component, HostListener, ViewChildren, QueryList } from '@angular/core';
import { PODLocalFile, PodService } from '../../pod.service';
import { AttachedFileUtil } from '@services/attached-file-util';
import StopEntity from '../../Entity/StopEntity';
import TaskEntity from '../../Entity/TaskEntity';
import { DateUtil } from '@services/date-utils';
import { Const } from '@const/Const';
import { WarpId } from '@wearewarp/universal-libs';
import { Utils } from '@services/utils';
import to from 'await-to-js';
import { DrawerService } from '@app/drawers/drawer.service';
import { Issue } from '../issue/issue.component';
import { Const as ConstUniversal } from '@wearewarp/universal-libs';
import { PodItemComponent } from '../pod-item/pod-item.component';
import { ImageUtil } from '@services/image-util';

@Component({
  selector: '[pod-detail]',
  templateUrl: './pod-detail.component.html',
  styleUrls: ['./pod-detail.component.scss']
})
export class PodDetail extends BaseComponent{
  @ViewChildren(PodItemComponent) podItemComponents: QueryList<PodItemComponent>;
  @HostListener('document:keyup', ['$event'])
  handleKeyBoardEvent(event: KeyboardEvent){
    if(event.key === 'Escape'){
      if(this.isInSelectMode) {
        this.exitSelectMode()
      }
    }
  }
  public isLoading: boolean = true;
  public isSubmitting: boolean = false;
  public isDeleting: boolean = false;
  public isUploading: boolean = false;
  public presentStop: StopEntity | null;
  public presentTask: TaskEntity | null;
  public arrayPOD: PODLocalFile[] = [];
  public isLoadingIssue: boolean = true;
  public shouldShowSelectCheckBox: any = {};
  public checkboxModel: any = {};
  public isInSelectMode: boolean = false;
  public get isStopLevel() { return !this.presentTask }
  public get isTaskLevel() { return !!this.presentTask }
  get selectedPodsText(){
    let count = 0;
    for(let key in this.checkboxModel){
      if(this.checkboxModel[key]) count++;
    }
    return `${count} POD${count > 1 ? 's' : ''} selected`;
  }
   
  constructor(private podService: PodService) {
    super();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.podService.loading.subscribe((data: boolean) => {
        this.isLoading = data;
      })
    );
    this.subscription.add(
      this.podService.podsChange.subscribe(() => {
        this.prepareData();
      })
    )
  }

  private prepareData(){
    this.presentStop = this.podService.getSelectedStop();
    this.presentTask = this.podService.getSelectedTask();
    this.arrayPOD = this.podService.getPodArray();
    this.arrayPOD.forEach(podItem => {
      this.shouldShowSelectCheckBox[podItem._id] = false;
      this.checkboxModel[podItem._id] = false;
    })
  }

  public attachEventSelectPod(fileId){
    const element = document.getElementById(fileId);
    if(!element) return;
    element.removeAllListeners();
    element.addEventListener("mouseover", (e) => {
      this.shouldShowSelectCheckBox[fileId] = this.isInSelectMode || true;
    })
    element.addEventListener("mouseout", (e) => {
      const rect = element.getBoundingClientRect();
      const inDiv = (
        e.clientX >= rect.left &&
        e.clientX <= rect.right &&
        e.clientY >= rect.top &&
        e.clientY <= rect.bottom
      );
      if(!inDiv) this.shouldShowSelectCheckBox[fileId] = this.isInSelectMode || false;
    })
  }

  public get stopInfo(){
    let type = this.presentStop?.getType() == ConstUniversal.TaskType.PICKUP ? 'PU' : 'DO';
    return `STOP ${this.podService.getSelectedStopIndex()} - ${type}`
  }

  public get taskInfo(){
    let shipment = this.presentTask?.getShipment();
    if(!shipment) return '';
    return shipment.getCodeText();
  }

  public isGroupBtnAvailable(){
    return this.isInSelectMode;
  }

  public onSelectPod(value){
    if(value) this.isInSelectMode = true;
    else{
      let flag: boolean = false;
      for(let key in this.checkboxModel){
        flag = flag || this.checkboxModel[key];
      }
      if(!flag) this.isInSelectMode = false;
    }
    this.updateShouldShowCheckbox();
  }

  public exitSelectMode(){
    this.isInSelectMode = false;
    for(let key of Object.keys(this.checkboxModel)){
      this.checkboxModel[key] = false;
    }
    this.updateShouldShowCheckbox()
  }

  private updateShouldShowCheckbox(){
    if(this.isInSelectMode){
      for(let key of Object.keys(this.shouldShowSelectCheckBox)){
        this.shouldShowSelectCheckBox[key] = true;
      }
    }
    else{
      for(let key of Object.keys(this.shouldShowSelectCheckBox)){
        this.shouldShowSelectCheckBox[key] = false;
      }
    }
  }

  public isPdf(item: PODLocalFile): boolean {
    return AttachedFileUtil.isPdf(item);
  }

  public getTasks(){
    const tasks = this.podService.getpresentJob()?.tasks || [];
    return tasks.map(task => {
      return {...task, shipmentStatus: this.getShipmentStatus(task.shipmentId)};
    })
  }

  formatDate(date) {
    return DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME_SHORT);
  }

  public showShipmentWarpId(shipment: number | { warpId: number }): string {
    return WarpId.showShipment(shipment);
  }

  public refreshPodChange(){
    this.podService.fetchRoute(this.podService.getpresentJob()?.id, this.podService.getSelectedStop()?.getId(), this.podService.getSelectedTask()?.getId(), false);
  }

  isConfirmPOD(item) {
    if (item?.podConfirmed?.when) return true;
    return false;
  }

  getShipmentStatus(shipmentId) {
    let status = this.podService.getShipmentById(shipmentId).getStatus();
    if (ConstUniversal.ShipmentStatus.pickupFailed === status || ConstUniversal.ShipmentStatus.dropoffFailed === status) return 'Failed';
    else if (ConstUniversal.ShipmentStatus.canceled === status) return this.getStatusShipment(status);
    else return '';
  }


  async onBtnConfirmAll(){
    this.isSubmitting = true;
    Promise.all(this.podItemComponents.map(podItem => podItem.confirmPod()))
    .then(() => {
      this.isSubmitting = false;
      this.showSuccess('Confirm all PODs successfully');
      this.refreshPodChange()
     this.exitSelectMode()
    })
    .catch(err => {
      this.isSubmitting = false;
      this.showErr(err)
    });
  } 

   deleteAll(){
    this.confirmDeletion({
      message: "Are you sure you want to delete this POD?",
      txtBtnOk: "Yes",
      fnOk:  () => {
        let data: any = []
        for(let item of this.arrayPOD){
          if(this.checkboxModel[item._id]){
            let param: any = {};
            param.uploadPodFileId = item._id;
            if(item?.taskId){
              param.taskIds = [item.taskId];
            }else{
              param.taskIds = item.taskIds;
            }
            data.push(param);
          }
        }
        this.isDeleting = true;
        this.api.POST(`${Const.APIV2(Const.APIURI_TASKS)}/deleteManyPodsForListTasksOfStop`, {data}).subscribe(
          (resp) => {
            this.isDeleting = false;
            this.showSuccess('Delete PODs successfully');
            this.refreshPodChange();
            this.exitSelectMode()
          },
          (err) => {
            this.isDeleting = false;
          this.showErr(err);
          }
        )
      }
    })
  }

  public async downloadAll(){
    let promises: any = []
    for(let item of this.arrayPOD){
      if(this.checkboxModel[item._id]){
        promises.push(this.downloadAttachedFile(item));
      }
    }
    Promise.all(promises).then(() => {
      this.exitSelectMode()
    }).catch(err => {
      this.showErr(err);
    })
  }

  public onClickAddPod(event){
    let element: HTMLElement;
    element = document.getElementById(`uploadRoutePODInput`) as HTMLElement;
    element.click();
  }

  async onFileSelected(inputElement: HTMLInputElement) {
    let files = inputElement.files;
    const listFile = Array.from(files);
    inputElement.value = "";
    try {
      this.isUploading = true;
      for(let file of listFile){
        const blob = await ImageUtil.resizeImageIfNeeded(file);
        if(this.presentTask?.getId())
            await this.uploadPOD(blob, blob.name, [this.presentTask.getId()]);
        else {
          let tasks = this.presentStop?.getTasks() || [];
          await this.uploadPOD(blob, blob.name, tasks.map(task => task?.getId()));
        }
      }
      this.isUploading = false;
      this.refreshPodChange()
      this.showSuccess("Your POD has been added successfully.");
    } catch (error) {
      this.isUploading = false;
      this.showErr(error);
    }
    
  }

  private async uploadPOD(file: Blob, fileName: string, taskIds: string[]) {
    let apiUrl = `${Const.APIV2(Const.APIURI_TASKS)}/uploadPodForListTasksOfStop`;
    let formData = new FormData();
    const jsonData = {
      'taskIds': taskIds,
    }
    formData.append("params", JSON.stringify(jsonData));
    formData.append("uploadPOD", file, fileName);
    await this.api.postFormData(apiUrl, formData).toPromise();
  }

  trackPod(index, item){
    return item._id;
  }
}
