<form [formGroup]="formInput" nz-form (keydown.enter)="$event.preventDefault()" style="margin: 0px; padding: 0px;">
    <div style="width: 100%">
      <div style="display: flex; justify-content: space-between;align-items: center; width: 100%; padding-left: 4px">
        <nz-tabset [(nzSelectedIndex)]="selectedTabIndex" [nzSize]="'small'" nzAnimated="false" style="width: 100%" type="card">
          <ng-container *ngFor="let tab of filterTabs">
            <nz-tab [nzTitle]="titleTemplate" style="font-weight: 600"></nz-tab>
            <ng-template #titleTemplate>
              <nz-badge [nzCount]="tab?.total" [nzOverflowCount]="99999" nzShowZero [nzStyle]="{ backgroundColor: '#1b1ac4'}">
                <span>{{tab.title}}</span>
              </nz-badge>
            </ng-template>
          </ng-container>
        </nz-tabset>
        <div style="display: flex;align-items: center;">
        </div>
      </div>
      <div class="form-filter">
        <div class="form-item" style="flex: 1; min-width: 250px;">
          <div class="flex">
            <search-box #searchBox (doSearch)="doSearch($event)"
              [placeHolder]="'Search by Financial Job, Route, Shipment Id or Order ref no'" [searchKeyword]="searchKeyword"
              style="min-width: 200px; width: 100%;"></search-box>
          </div>
        </div>
        <div class="form-item" style="width: 250px;">
          <div>
            <nz-select nzBackdrop="true" nzAllowClear nzShowSearch
              [formControlName]="'carrierId'" [nzLoading]="isFetchingCarrier"
              [nzPlaceHolder]="'Select carrier'"
              (ngModelChange)="onChange($event, 'carrierId')" style="width: 100%;">
              <nz-option *ngFor="let item of listCarriers" 
                [nzValue]="item.id" [nzLabel]="getCarrierName(item)">
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div class="form-item" style="width: 250px;">
          <div>
            <nz-select nzBackdrop="true" nzAllowClear nzShowSearch
              [formControlName]="'clientId'" [nzLoading]="isFetchingClient"
              [nzPlaceHolder]="'Select customer'"
              (ngModelChange)="onChange($event, 'clientId')" style="width: 100%;">
              <nz-option *ngFor="let item of listClients" 
                [nzValue]="item.id" [nzLabel]="getClientName(item)">
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div class="form-item" style="width: 250px;">
          <div>
            <nz-select nzBackdrop="true" nzAllowClear nzShowSearch
              [formControlName]="'paymentTerm'"
              [nzPlaceHolder]="'Select Payment Term'"
              (ngModelChange)="onChange($event, 'paymentTerm')" style="width: 100%;">
              <nz-option *ngFor="let item of paymentTerms" 
                [nzValue]="item" [nzLabel]="getPaymentTermName(item)">
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div class="form-item" >
          <div>
            <button nz-button nzType="primary" (click)="openQuickUploadPOD()">Quick upload PODs</button>
          </div>
        </div>
      </div>
    </div>
  </form>
    