import { Component, Input, OnInit } from '@angular/core';
import { InputHelper } from '@services/input-helper';
import { FinJob } from '@wearewarp/types/data-model';

@Component({
  selector: 'pc-payable-amount',
  templateUrl: './payable-amount.component.html',
  styleUrls: ['./payable-amount.component.scss', '../../style.scss']
})
export class PayableAmountComponent implements OnInit {
  @Input() listFinJobs: FinJob;
  constructor() { }

  ngOnInit(): void {
  }

  getAmtDesc(finJob) {
    if (finJob.isAdditional) {
      return `Additional payable amount`;
    } else {
      return `Payable amount`;
    }
  }

  showFinJobAmt(finJob) {
    return '$' + InputHelper._formatMoney(finJob.amt, 2);
  }

}
