<div cdkDrag>
  <div class="options">
    <div class="">
      <div *ngFor="let shipment of shipments" class="shipment">
        <div class="shipment-label">
          <div><span class="label">{{getLabel(shipment)}}</span> <span class="status ml-5">{{getStatus(shipment)}}</span></div>
          <div class="stops">{{getStops(shipment)}}</div>
        </div>
        <div class="checkbox">
          <ng-container *ngFor="let type of shipment.taskTypes || []">
            <label nz-checkbox [(ngModel)]="selecteds[getTaskIdForShipmentByType(shipment, type)]">{{getTaskTypelabel(type)}}</label>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="button-submit">
    <button nz-button nzType="primary" [nzLoading]="(isSubmitting)" (click)="onSubmit()"
      [disabled]="shouldDisableAssignButton()">{{getBtnText()}}</button>
  </div>
</div>