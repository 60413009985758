import { BaseComponent } from '@abstract/BaseComponent';
import { Component, Input, OnInit } from '@angular/core';
import { JobV2 } from '../../interface';

@Component({
  selector: 'rate-confirmation',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss']
})
export class RateConfirmation extends BaseComponent {
  @Input() job: JobV2
  constructor() {
    super()
  }

  ngOnInit(): void {
  }

}
