import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { Utils } from "@services/utils";
import TaskEntity from "../../Entity/TaskEntity";
import ShipmentEntity from "../../Entity/ShipmentEntity";
import { ShipmentWithStop } from "../pod-item/pod-item.component";

@Component({
  selector: "pod-confirmation-assign-pod",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class RouteAssignPodTasks extends BaseComponent {
  private _podItem: any;

  @Input() refreshData: Function;
  @Input() tasks: TaskEntity[];
  @Input() shipments: ShipmentWithStop[]
  @Input() set podItem(value) {
    this._podItem = value;
    this.initChecked();
  }
  get podItem() {
    return this._podItem;
  }

  isSubmitting: boolean = false;
  checkedAllPods: boolean;
  indeterminatePods: boolean;
  public selecteds: { [key: string]: boolean } = {};
  public originalSelectedsShipmentLevel: { [key: string]: boolean};
  public shipmentMatchSuggestion = {};

  ngOnChanges() {
    this.buildShipmentMatchSuggestion();
  }

  buildShipmentMatchSuggestion() {
    let shipmentMatching = this.podItem?.shipmentMatching || [];
    for (let item of shipmentMatching) {
      let shipmentId = item?.shipmentId || '';
      this.shipmentMatchSuggestion[shipmentId] = item?.accuracy || 0;
    }
  }

  public initChecked() {
    this.selecteds = {};
    this.tasks.forEach(it => {
      this.selecteds[it.getId()] = it.getPodUploadFiles()?.includes(this.podItem?._id) ? true : false; 
    });
    this.originalSelectedsShipmentLevel = {...this.selecteds};
    this.refreshCheckAllPods();
  }
  public refreshCheckAllPods() {
    this.checkedAllPods = this.tasks.every(it => this.selecteds[it.getId()]);
    this.indeterminatePods = this.tasks.some(it => this.selecteds[it.getId()]) && !this.checkedAllPods;
  }

  public onCheckAllPodsShipmentLevel(checked: boolean) {
    this.tasks.forEach(it => this.selecteds[it.getId()] = checked);
    this.refreshCheckAllPods();
  }

  public getBtnText(){
    return 'Assign'
  }

  public async onSubmit() {
    await this.updatePodTask();
  }

  public getParamsUpdate(){
    return this.tasks.map(it => ({ id: it.getId(), selected: this.selecteds[it.getId()] }))
  }
  public async updatePodTask() {
    this.isSubmitting = true;
    const jobId = this.tasks?.[0]?.getJobId();
    const tasksBodyItems = this.getParamsUpdate()
    // console.log(jobId, this.podItem, this.tasks, this.selecteds)
    delete this.podItem.loadingTask; //prevent error Converting circular structure to JSON
    await this.api.POST(`${Const.APIV2(Const.APIURI_JOBS)}/${jobId}/update-pod-tasks`, {
      pod: this.podItem,
      tasks: tasksBodyItems
    }).toPromise();

    this.isSubmitting = false;
    this.refreshData(true);
    this.showSuccess("Update POD tasks successfully");
  }
  
  public shouldShowDanger(){
    return !this.checkedAllPods && !this.indeterminatePods;
  }
  public shouldDisableAssignButton(){
      if(!this.checkedAllPods && !this.indeterminatePods) return true;
      for(let key of Object.keys(this.selecteds)){
        if(this.selecteds[key] !== this.originalSelectedsShipmentLevel[key]) return false;
      }
      return true;
  }

  hasShipmentMatchSuggestion(shipmentId): boolean {
    return this.shipmentMatchSuggestion[shipmentId] ? true : false;
  }

  showMatchAccuracy(shipmentId) {
    let accuracy = this.shipmentMatchSuggestion[shipmentId] || 0;
    accuracy = accuracy ? (accuracy * 100).toFixed(1) : 0;
    return `(Matched ${accuracy || '-'}%)`;
  }

  getLabel(shipment: ShipmentWithStop){
    return this.showShipmentCode(shipment.shipment.toJSON())
  }

  getStops(shipment: ShipmentWithStop){
    return shipment.stops;
  }

  getTaskIdForShipmentByType(shipment: ShipmentWithStop, type: any){
    const shipmentEntity = shipment.shipment;
    const task = this.tasks.find(task => task.getShipmentId() == shipmentEntity.getId() && task.getType() == type.type);
    return task?.getId();
  }

  getTaskTypelabel(type){
    return type.label || ''
  }

  getStatus(shipment: ShipmentWithStop){
    return this.getShipmentStatus(shipment.shipment.getStatus())
  }
}