import { BaseComponent } from '@abstract/BaseComponent';
import { Component, Input, OnInit } from '@angular/core';
import { CarrierMeta, DriverMeta } from '@wearewarp/types/data-model/types/Metadata';

@Component({
  selector: 'pc-recipent',
  templateUrl: './recipent.component.html',
  styleUrls: ['./recipent.component.scss', '../../style.scss']
})
export class RecipentComponent extends BaseComponent {
  @Input() carrier: CarrierMeta;
  @Input() driver: DriverMeta;
  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  get carrierName(){
    return this.carrier?.basicInfo?.name || 'N/A';
  }

  get mc(){
    return this.carrier?.basicInfo?.mc || 'N/A';
  }

  get contactInfos(){
    let contacts = this.carrier?.basicInfo?.contacts || [];
    return contacts.map(it => `${it?.firstName + ' ' + it?.lastName || it?.fullName} | ${this.displayPhone(it?.phone) || 'N/A'} | ${it?.email}`)
  }

  get driverName(){
    return this.driver?.fullName || this.driver?.firstName + ' ' + this.driver?.lastName;
  }

  get driverPhone(){
    return this.displayPhone(this.driver?.phone) || 'N/A';
  }

}
