import { Const } from "@const/Const";
import { Shipment } from "@wearewarp/types/data-model";
import { PodService } from "../pod.service";
import { Utils } from "@services/utils";
import { WarpId } from "@wearewarp/universal-libs";

export default class ShipmentEntity {
  private data: Shipment;
  constructor(private podService: PodService) {}

  init(shipment: Shipment) {
    this.data = shipment;
    return this;
  }
  getParent(){
    if(this.data.shipmentTransitType != Const.ShipmentTransitType.leg) return null;
    return this.data.metadata?.parent as any;
  }

  getOrderRefs(){
    let refNums: any = [];
    let deliveryInfos: any = [];
    if (this.data.isCrossDock) {
      let parent = this.getParent();
      if(!parent) return refNums;
      deliveryInfos = parent?.deliveryInfos;
    } else {
      deliveryInfos = this.data?.deliveryInfos
    }
    if(!deliveryInfos) return refNums;
    deliveryInfos.forEach((task) => {
      if(task.type == Const.TaskType.PICKUP || task.type == Const.TaskType.DROPOFF) {
        refNums = refNums.concat(task.refNums || []);
      } 
    })
    return Utils.uniqElementsArray(refNums).filter(it => !!it)
  }

  getShipmentTransitType(){
    return this.data.shipmentTransitType;
  }

  getId() {
    return this.data.id;
  }

  getWarpId() {
    return this.data.warpId;
  }

  getClientId() {
    return this.data.clientId;
  }

  getClient() {
    return this.data.metadata?.client;
  }

  getPickInfo() {
    return this.data.deliveryInfos?.find(item => item.type == Const.TaskType.PICKUP);
  }

  getDropInfo() {
    return this.data.deliveryInfos?.find(item => item.type == Const.TaskType.DROPOFF);
  }

  getItems() {
    return this.data.itemIds.map(itemId => this.podService.getShipmentItemById(itemId))
  }

  getStatus() {
    return this.data.status || Const.OrderStatus.needCarrier;
  }

  getTrackingCode() {
    return this.data.trackingCode;
  }

  getShipmentType() {
    return this.data.shipmentType
  }

  getShipmentModeId() {
    return this.data.shipmentModeId
  }

  getEquipmentId() {
    return this.data.equipmentId
  }

  getTempRange() {
    return this.data.metadata?.tempRange
  }

  getOrderId(){
    return this.data.orderId
  }

  getDetailUrl() {
    return `${Const.routeAdminOrderList}/${this.data.orderId}`
  }

  getTags() {
    return this.data.tags || []
  }

  toJSON() {
    return {
      ...this.data
    }
  }
  getCodeText() {
    return WarpId.showShipmentCode(this.data);
  }

  getCode(){
    if(this.data?.code) return this.data?.code;
    return this.data?.warpId;
  }

  getLastJobId(){
    return this.data?.lastJobId;
  }
}