import { Carrier,User,Driver } from '@wearewarp/types/data-model';
import { Job } from '@wearewarp/types/data-model';
import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from '@dialogs/dialog.service';
import { SendEmailRequestPod } from '../send-email-request-pod/send-email-request-pod.component';
import { PodService } from '../../pod.service';
import { BaseComponent } from '@abstract/BaseComponent';
import { Utils } from '@services/utils';
import { DateUtil } from '@services/date-utils';
import { Const } from '@const/Const';
import { DrawerService } from '@app/drawers/drawer.service';
import { NoteAndHistory } from '../note-and-history/note-and-history.component';
import { WarpId } from '@wearewarp/universal-libs';


@Component({
  selector: '[pod-confirmation-header]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class podConfirmationHeader extends BaseComponent{
  @Input() job: Job;
  public displayInfo: any = {};
  constructor(private podService: PodService) {
    super()
   }

  ngOnChanges(): void {
    this.buildDisplayInfo()
  }

  public buildDisplayInfo(){
    if(!this.job) return;
    const displayInfo = {
      jobId: this.job.id,
      jobCode: this.getJobCode(this.job),
      listClientName: this.getListClientName(this.job),
      completedWhen: this.formatDate(this.job?.completedWhenBy?.when),
      completedBy: this.getCompleteBy(this.job?.completedWhenBy)
    }
    this.displayInfo = displayInfo;
  }

  public getJobCode(job: Job){
    if(!job) return '';
    return job?.code ?? '';
  }

  public getListClientName(job: Job){
    if(!job) return ''
    const clients = job?.clients;
    if(!clients || !clients?.length) return '';
    return clients.map(client => client?.name).join(', ')
  }

  public openSendEmailDialog(){
    const job = this.podService.getpresentJob();
    DialogService.openFormDialog1(SendEmailRequestPod, {
      nzComponentParams: {
        job: job,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.showSuccess('Send email to carrier success.');
          this.refreshPodChange()
        },
      },
      nzWidth: 646,
      nzClassName: 'model-no-padding',
      nzCentered: true,
      nzClosable: true,
      nzTitle: 'Request POD email',
    })
  }

  getLastSentRequest(item) {
    if(!item) return false;
    let historyArr = item?.historySendRequestPOD ?? [];
    if (Utils.isArrayNotEmpty(historyArr)) {
      let lastTime = historyArr[historyArr.length - 1]?.when ?? '';
       return DateUtil.format(lastTime, Const.FORMAT_GUI_DATETIME)
    }
    return false;
  }

  public formatDate(date){
    if(!date) return 'N/A';
    return DateUtil.formatDate(date, Const.FORMAT_GUI_DATE);
  }
  public getCompleteBy(completedWhenBy: any){
    if(!completedWhenBy) return 'N/A';
    if(completedWhenBy?.byDriver){
      let driver = completedWhenBy?.byDriver;
      return `Driver ${driver?.firstName ||''} ${driver?.lastName ||''}`;
    }
    if(completedWhenBy?.byCarrier){
      let carrier = completedWhenBy?.byCarrier;
      return `Driver ${carrier?.basicInfo?.name ||''}`
    }
    if(completedWhenBy?.by){
      let user = completedWhenBy?.by;
      return user?.fullName || `${user?.firstName || ''} ${user?.lastName || ''}`;
    }
    return 'N/A'
  }

  private refreshPodChange(){
    let jobId = this.podService.getpresentJob()?.id;
    this.podService.fetchRoute(jobId);
  }
  public onBtnViewIssue() {
    let jobId = this.podService.getpresentJob()?.id;
    DrawerService.openDrawer1(NoteAndHistory, {
      nzContentParams: {
        jobId
      },
      nzWidth: 400,
      nzWrapClassName: 'wrap-drawer route-pod-drawer',
    });
  }

  public getDisplayId() {
    let showCodes = this.job?.shipments?.map(item => {
      const hasCode = !!item?.code;
      const hasWarpId = !!item?.warpId;
      if (hasCode && hasWarpId) {
        return `${item.code} (${WarpId.showShipment(item.warpId)})`;
      } else if (hasCode && !hasWarpId) {
        return item.code;
      } else if (!hasCode && hasWarpId) {
        return WarpId.showShipment(item.warpId);
      }
      return '';
    });
    if (showCodes) {
      if(showCodes.length < 4) return showCodes.join(', ');
      let data = [...showCodes];
      let newArray = data.slice(0, 4);
      return `${newArray.join(', ')} (+${showCodes.length - 4})`;
    }
  }

  getShipmentCount() {
    let sNum = this.job?.shipments?.length;
    if (sNum > 1) return `${sNum} Shipments:`;
    else return `${sNum} Shipment:`;
  }

  getAllShipmentCodeAndWarpId() {
    let arr = this.job?.shipments?.map(item => {
      const hasCode = !!item?.code;
      const hasWarpId = !!item?.warpId;
      if (hasCode && hasWarpId) {
        return `${item.code.replace('S-', '')} ( ${item.warpId} )`;
      } else if (hasCode && !hasWarpId) {
        return item.code.replace('S-', '');
      } else if (!hasCode && hasWarpId) {
        return item.warpId;
      }
      return '';
    });
    return arr?.join(', ');
  }
}