<div class="header-content">
    <div class="flex">
        <a class="link" [routerLink]="[routeAdminPodConfirmationList]">
            <span nz-icon nzType="arrow-left" nzTheme="outline"></span>
        </a>
        <div class="content">
            <div style="display: flex; justify-content: start; gap: 8px;">
                <div class="jobcode">{{displayInfo?.listClientName}} - {{displayInfo?.jobCode}}</div>
                <a [routerLink]="[routeAdminDispatchList, displayInfo?.jobId]" target="_blank" nz-tooltip
                nzTooltipTitle="Go to dispatch"><i nz-icon nzType="rocket" nzTheme="outline" style="margin-left: 5px;"></i></a>
            </div>
            <div class="flex customer">
                <div class="bold right5">{{getShipmentCount()}}</div>
                <div>{{getDisplayId()}}</div>
                <a><copy-text class="left10" text="{{ getAllShipmentCodeAndWarpId() }}" tooltip="Copy all NEW & OLD WARPID" icon="copy"></copy-text></a>
            </div>
        </div>
    </div>
    <div class="send-request">
        <ng-container *ngIf="!getLastSentRequest(job)">
            <button nz-button class="send-email-button" (click)="openSendEmailDialog()">
                <span nz-icon nzType="send" nzTheme="outline"></span>
                <span>Request POD</span>
            </button>
        </ng-container>
        <ng-container *ngIf="getLastSentRequest(job)">
            <div class="request-sent">
                Last sent request on 
                <span>{{getLastSentRequest(job)}}</span>
            </div>
        </ng-container>
        <a class="link-btn" (click)="onBtnViewIssue()">View note & history</a>
    </div>
</div>
