<div class="rate-container">
    <div class="flex-1 column-left">
        <pc-recipent [carrier]="job?.carrier" [driver]="job?.driver"></pc-recipent>
        <pc-payment [paymentTerm]="job?.carrier?.paymentTerm"></pc-payment>
        <pc-document [bol]="job?.bol" [loadTender]="job?.loadTender"></pc-document>
    </div>

    <div class="flex-1">
        <pc-payable-amount [listFinJobs]="job?.finJobs"></pc-payable-amount>
    </div>
</div>