import { Component, EventEmitter, Input, OnChanges, Output, TemplateRef, ViewChild } from '@angular/core';
import { PODLocalFile, PodService } from '../../pod.service';
import TaskEntity from '../../Entity/TaskEntity';
import ShipmentEntity from '../../Entity/ShipmentEntity';
import { Utils } from '@services/utils';
import { AttachedFileUtil } from '@services/attached-file-util';
import * as pdfjsLib from "pdfjs-dist";
import { Const as WarpConst} from '@wearewarp/universal-libs';
import { DateUtil } from '@services/date-utils';
import { Const } from '@const/Const';
import { BaseComponent } from '@abstract/BaseComponent';
import to from 'await-to-js';
import { DialogService } from '@dialogs/dialog.service';
import { ViewPodComponent } from '../view-pod/view-pod.component';
import { DrawerService } from '@app/drawers/drawer.service';
import { Issue } from '../issue/issue.component';
import { PdfFilePodService } from './pdfFilePod.service';
import { ImageLocal } from './pdfFilePod.interface';
import { ViewImageOnlyComponent } from '../view-pod/view-image-only';

export interface ShipmentWithStop{
  shipment: ShipmentEntity,
  taskTypes: Array<{
    type: string,
    label: string
  }>
  stops: string
}

@Component({
  selector: 'pc-pod-item',
  templateUrl: './pod-item.component.html',
  styleUrls: ['./pod-item.component.scss'],
  providers: [PdfFilePodService]
})
export class PodItemComponent extends BaseComponent {
  @ViewChild('podModalheader', {static: true}) podModalHeader: TemplateRef<any>;
  private prevFileId: any = null;
  private _file;
  @Input() set file(value: PODLocalFile){
    this._file = value;
    if(!this.file) return;
    setTimeout(() => this.addEventToUnConfirmBtn(), 1)
    if(value?._id && value._id == this.prevFileId ) return;
    this.prevFileId = value._id;
    this.preparePodUrlForTask();
    this.prepareShipments(value);
    if (this.isPdf(value)) {
      this.pdfPodService.setPdfFilePod(value);
    }
    if (this.file?.shipmentMatching) {
      this.currentMatchingData = this.file.shipmentMatching;
    }
  };
  get file(){
    return this._file;
  }

  private prepareShipments(value: PODLocalFile){
    this.shipmentEntities = [];
    let arr = this.podService.getTaskEntities();
    arr.forEach(task => this.taskChecked[task.getId()] = false);
    const taskEntities = arr.filter(task => task.getPodUploadFiles().includes(value._id));
    // taskEntities.forEach(task => this.taskChecked[task.getId()] = true);
    let shipmentIds = taskEntities.map(task => task.getShipmentId());
    let shipmentMatching = value?.shipmentMatching || [];
    if (this.isPdf(value) && this.pdfPodService.getSelectedIndex() !== null) {
      shipmentMatching = value?.shipmentMatchingForPages?.find(item => item.pageIndex === this.pdfPodService.getSelectedIndex())?.shipmentMatching || [];
    }
    shipmentMatching.forEach(item => {
      if(item.accuracy) shipmentIds.push(item.shipmentId);
    })
    shipmentIds = Utils.uniqElementsArray(shipmentIds);
    this.shipmentEntities = this.podService.getShipmentEntities().filter(shipment => shipmentIds.includes(shipment.getId()));
    this.shipmentEntities = this.shipmentEntities.sort((a, b) => {
      const matchA = shipmentMatching.find(item => item.shipmentId == a.getId())?.accuracy || 0;
      const matchB = shipmentMatching.find(item => item.shipmentId == b.getId())?.accuracy || 0;
      return matchB - matchA
    });
    if (this.shipmentEntities?.length > 0) {
      let firstMatchingTaskId = taskEntities?.find(it => it.getShipmentId() === this.shipmentEntities[0].getId())?.getId();
      if (firstMatchingTaskId) {
        this.taskChecked[firstMatchingTaskId] = true;
      }
    }
  }

  @Input() isDisableConfirm : boolean = false;
  @Input() isSelected: boolean = false;
  @Output() viewInIted : EventEmitter<any> = new EventEmitter<any>()
  
  constructor(private podService: PodService, private pdfPodService: PdfFilePodService) {
    super();
    this.refreshData = this.refreshData.bind(this);
  }

  
  public shipmentEntities: ShipmentEntity[] | null; // lưu những shipment có task đang được assign cho  pod này 
  public taskChecked: any = {};
  public isSubmitting: boolean = false;
  public isLoadingIssue: boolean = false;
  public countIssue;
  public id = Utils.generateULID();
  public iconBtnConfirmed: string = 'check';
  public textBtnConfirmed: string = 'Confirmed';
  public shipmentWithStop: ShipmentWithStop[] = [];
  public allTasks: TaskEntity[] = [];
  public pdfImages: ImageLocal[] = [];
  public currentMatchingData;

  ngOnInit(): void {
    this.buildShipmentWithStop();
    this.allTasks = this.podService.getTaskEntities();
    this.getIssue(this.file)
    this.subscription.add(this.pdfPodService.images.subscribe({
      next: images => {
        this.pdfImages = images;
      }
    }));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewInIted.emit(this.file._id);
    }, 1);
  }

  ngOnDestroy() {
    this.pdfPodService.clearImages();
    super.ngOnDestroy();
  }

  private addEventToUnConfirmBtn(){
    if(!this.isConfirmed(this.file)) return;
    const element = document.getElementById(this.id);
    element.addEventListener("mouseover", () => {
      this.iconBtnConfirmed = 'rollback';
      this.textBtnConfirmed = 'Unconfirm'
    })
    element.addEventListener("mouseout", () => {  
      this.iconBtnConfirmed = 'check';
      this.textBtnConfirmed = 'Confirmed'
    })
  }

  private getIssue(pod: PODLocalFile){
    this.isLoadingIssue = true;
    this.api.GET(`${Const.APIURI_CONVERSATIONS}/?subjectId=${pod?._id}&subjectType=pod&type=note`).subscribe(
      (resp) => {
        this.isLoadingIssue = false;
        this.countIssue = resp?.data?.count || 0;
      },
      (err) => {
        // this.showErr(err);
        this.isLoadingIssue = false;
      }
    );
  }

  private buildShipmentWithStop(){
    const shipments = this.podService.getShipmentEntities();
    const stops = this.podService.getStops();
    const tasks = this.podService.getTaskEntities();
    let rs: ShipmentWithStop[] = [];
    for(let shipment of shipments){
      const taskTypes = this.getTaskTypesOfShipment(shipment);
      const taskIdOfShipment = tasks.filter(task => task.getShipmentId() == shipment.getId()).map(task => task.getId());
      let stopStr: string[] = [];
      for(let i in stops){
        const stop = stops[i];
        const taskIds = (stop.getTasks() || []).map(it => it.getId());
        if(taskIds.some(it => taskIdOfShipment.includes(it))){
          stopStr.push(`STOP ${Number(i)+1}`)
        }
      }
      rs.push({
        shipment,
        taskTypes: taskTypes.map(type => ({type, label: this.getTaskTypelabel(type)})),
        stops: stopStr.join(', ')
      })
    }
    this.shipmentWithStop = rs;
  }

  private async preparePodUrlForTask() {
    // this.viewInIted.emit(this.file._id);
    if(this.isPdf(this.file)) this.loadPdfPod();
  }

  public isPdf(item: PODLocalFile): boolean {
    return AttachedFileUtil.isPdf(item);
  }

  private async loadPdfPod(){
    let url = await this.file.localUrl();
    this.file.loadingTask = pdfjsLib.getDocument({url, withCredentials: true});
    this.file.loadingTask.promise.then(
      (pdf) => {
        pdf.getPage(1).then( (page) => {
          var desiredWidth = 48; // css class attached-pod
          var viewport = page.getViewport({ scale: 1 });
          var scale = desiredWidth / viewport.width;
          var scaledViewport = page.getViewport({ scale: scale });
          var canvas = <HTMLCanvasElement>(
            document.getElementById(`pod-${this.file._id}`)
          );
          if (!canvas) return;
          var context = canvas.getContext("2d");
          //check file is rendered to avoid multiple render error
          var imageData = context.getImageData(0, 0, canvas.width, canvas.height);
          var allPixelsAreTransparent = !imageData.data.some(value => value !== 0);
          if(!allPixelsAreTransparent) return;
          canvas.height = scaledViewport.height;
          canvas.width = scaledViewport.width;
          var renderContext = {
            canvasContext: context,
            viewport: scaledViewport,
          };
          var renderTask = page.render(renderContext);
          renderTask.promise
            .then()
            .catch((e) => console.error("DPF render error ", e));
        });
      },
      function (err) {
        console.error("PDF loading error ", err);
      }
    )
  }

  getTaskTypelabel(type){
    switch(type){
      case WarpConst.TaskType.PICKUP: return 'PU';
      case WarpConst.TaskType.DROPOFF: return 'DO';
      case WarpConst.TaskType.RETURN:return 'RT';
      case WarpConst.TaskType.RETURN_DEPOT: return 'RTD';
      case WarpConst.TaskType.TRANSIT: return 'TS';
    }
  }

  getTaskTypesOfShipment(shipment: ShipmentEntity){
    const taskEntities = this.podService.getTaskEntities();
    const types: string[] = taskEntities.filter(task => task.getShipmentId() == shipment.getId()).map(it => it.getType());
    return Object.values(WarpConst.TaskType).filter(type => types.includes(type));
  }

  private getTaskForShipment(shipment, type){
    let tasks = this.podService.getTaskEntities().filter(task => task.getShipmentId() && task.getShipmentId() == shipment.getId());
    return tasks.find(task => task.getType() == type);
  }

  getTaskIdForShipmentByType(shipment, type){
    let task = this.getTaskForShipment(shipment, type);
    return task?.getId();
  }

  getPodMatchingForShipment(shipment){
    return this.currentMatchingData?.find(item => item.shipmentId == shipment.getId());
  }

  getTextactForShipment(shipment){
    let textact = this.getPodMatchingForShipment(shipment);
    if(!textact) return '';
    return textact?.metadata?.textAnalysis?.join(', ') || '';
  }

  getConfidentForShipment(shipment){
    let textact = this.getPodMatchingForShipment(shipment);
    if(!textact) return 0;
    return (textact.accuracy*100).toFixed(0) + '%';
  }

  getAllConfident(){
    const allShipments = this.podService.getShipmentEntities();
    const data = this.file?.shipmentMatching || [];
    let allConfident = data.map(item => {
      let shipment = allShipments.find(s => s.getId() == item.shipmentId);
      return {
        confident: (item.accuracy*100).toFixed(0) + '%',
        shipment: shipment?.getCodeText()
      }
    }) || [];
    return allConfident
  }

  isConfirmed(file: PODLocalFile){
    if (file?.podConfirmed?.when) return true;
    return false;
  }

  formatDate(date) {
    return DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME_SHORT);
  }

  async refreshData(){
    await this.podService.fetchRoute(this.podService.getpresentJob()?.id, this.file.stopId, this.file?.taskId, false);
    this.prepareShipments(this.file);
    this.buildShipmentWithStop();
    this.allTasks = this.podService.getTaskEntities();
  }

  public async confirmPod(isRefresh? : boolean){
    if (this.isPdfMultiplePage && this.hasSelectedPdfPage) {
      // upload pdf page for list tasks
      // chỉ áp dụng với pdf multipage
      this.confirmPageSelected();
      return;
    }
    // confirm pod cho origin input file
    if(this.isDisableConfirm && !this.isSelected) return;
    if(this.file?.podConfirmed?.when) return;
    this.isSubmitting = true;
    // assign pod to tasks
    const tasks = this.podService.getTaskEntities();
    const taskBodyItems = tasks.map(t => {
      return {
        id: t.getId(),
        selected: this.taskChecked[t.getId()]
      }
    });
    delete this.file.loadingTask;
    const jobId = this.podService.getpresentJob()?.id;
    await this.api.POST(`${Const.APIV2(Const.APIURI_JOBS)}/${jobId}/update-pod-tasks`, {
      pod: this.file,
      tasks: taskBodyItems
    }).toPromise();
    //confirm pod
    const params: any = {
      uploadPodFileId: this.file._id,
      taskIds : tasks.filter(task => this.taskChecked[task.getId()]).map(task => task.getId())
    } 
    await this.api.POST(`${Const.APIV2(Const.APIURI_TASKS)}/confirmPodForListTasksOfStop`, params).toPromise();
    this.isSubmitting = false;
    
    if(isRefresh) this.refreshData()
    setTimeout(() => this.addEventToUnConfirmBtn(), 1)
  }

  public unConfirmPOD(){
    this.confirmDeletion({
      message: "Are you sure you want to unconfirm this POD?",
      txtBtnOk: "Yes",
      fnOk: async () => {
        let params = {
          id: this.file._id,
        }
        let url = `${Const.APIURI_TASKS}/${this.file.taskId ? this.file.taskId : this.file.taskIds[0]}/un-confirm-pod`;
        this.file.onProgress = true;
        let [err] = await to(this.api.POST(url, params).toPromise());
        if(err) {
          this.showErr(err);
          return this.file.onProgress = false;
        }else {
          this.file.onProgress = false;
          this.refreshData();
        }
      }
    })
  }

  public confirmRemoveShipment(shipment: ShipmentEntity){
    this.confirmDeletion({
      message: "Are you sure you want to remove this shipment?",
      txtBtnOk: "Yes",
      fnOk: () => {
        this.removeTaskOfShipmentFromPOD(shipment)
      }
    })
  }

  async removeTaskOfShipmentFromPOD(shipment: ShipmentEntity){
    const tasks = this.podService.getTaskEntities();
    const taskOfShipment = tasks.filter(task => task.getShipmentId() == shipment.getId());
    taskOfShipment.forEach(task => this.taskChecked[task.getId()] = false);
    const taskBodyItems = tasks.map(t => {
      return {
        id: t.getId(),
        selected: this.taskChecked[t.getId()]
      }
    });
    delete this.file.loadingTask;
    const jobId = this.podService.getpresentJob()?.id;
    let [err] = await to(this.api.POST(`${Const.APIV2(Const.APIURI_JOBS)}/${jobId}/update-pod-tasks`, {
      pod: this.file,
      tasks: taskBodyItems
    }).toPromise());
    if(err) this.showErr(err);
    this.showSuccess('Tasks removed successfully');
    this.refreshData();
  }

  viewPOD(){
    if(!this.file) return;
    if (this.isPdf(this.file)) {
      this.selectOriginPdfFile();
    }
    DialogService.openDialog(ViewPodComponent, {
      nzComponentParams: {
        file: this.file
      },
      nzTitle: this.podModalHeader,
      nzFooter: null,
      nzMask: false,
      nzMaskClosable: false,
      nzClassName: 'modal-no-padding',
      nzWidth: '40vw',
      nzBodyStyle:{
        height: '750px',
        padding: '0',
      },
      nzStyle: {
        top: '10px',
        left:'51vw',
        margin: 0
      }
    })
  }

  public onBtnViewIssue() {
    DrawerService.openDrawer1(Issue, {
      nzContentParams: {
        podItem: this.file,
        refreshPodChange: () => {
          this.refreshData();
          this.getIssue(this.file)
        }
      },
      nzWidth: 400,
      nzWrapClassName: 'wrap-drawer route-pod-drawer',
    });
  }

  onClickShipment(shipment: ShipmentEntity){
    let task = this.getTaskForShipment(shipment, WarpConst.TaskType.DROPOFF);
    let focusElement : HTMLElement = document.getElementById(`task-${task.getId()}`);
    if(focusElement) {
      this.scrollToElement(focusElement);
      focusElement.classList.add('blinking-border');
      setTimeout(() => {
        focusElement.classList.remove('blinking-border');
      },3000)
    }
  }

  trackConfident(index, confident){
    return confident.shipment;
  }

  getPdfPageIndex(index: number){
    return index + 1;
  }

  onBtnViewPdfPage(index: number) {
    DialogService.openDialog(ViewImageOnlyComponent, {
      nzComponentParams: {
        imgUrl: this.pdfPodService.getImageByIndex(index)?.url,
        fileImage: this.pdfPodService.getImageByIndex(index)?.blob
      },
      nzTitle: `Page ${this.getPdfPageIndex(index)} / ${this.pdfImages?.length || 'N/A'}`,
      nzFooter: null,
      nzClassName: 'modal-no-padding',
      nzWidth: '40vw',
      nzBodyStyle:{ height: '750px', padding: '0' },
      nzStyle: { top: '10px', left:'51vw', margin: 0 }
    })
  }

  get isPdfMultiplePage() {
    return this.isPdf(this.file) && this.pdfImages?.length > 1;
  }

  get selectedPageIndex() {
    return this.pdfPodService.getSelectedIndex();
  }

  get hasSelectedPdfPage() {
    return this.selectedPageIndex !== null && this.selectedPageIndex !== undefined;
  }

  onSelectPageIndex(index: number) {
    // Nếu đang chọn rồi thì un-select
    if (this.selectedPageIndex === index) {
      this.selectOriginPdfFile();
    } else {
      this.pdfPodService.setSelectedIndex(index)
      this.currentMatchingData = this.file?.shipmentMatchingForPages?.find(item => item.pageIndex === index)?.shipmentMatching;
      this.prepareShipments(this.file);  
    }
  }

  selectOriginPdfFile() {
    this.pdfPodService.setSelectedIndex(null);
    this.currentMatchingData = this.file?.shipmentMatching;
    this.prepareShipments(this.file);
  }

  isConfirmedPage(index) {
    return this.pdfPodService.getImageByIndex(index)?.isConfirmed === true;
  }

  async confirmPageSelected() {
    this.isSubmitting = true;
    const tasks = this.podService.getTaskEntities();
    const taskCheckedIds = tasks.filter(task => this.taskChecked[task.getId()]).map(task => task.getId());
    
    let selectedImage = this.pdfPodService.getSelectedImage();
    await this.pdfPodService.uploadImageForListTasksOfStop(selectedImage, taskCheckedIds);
    this.showSuccess('Confirm pod successfully');
    this.isSubmitting = false;
    this.refreshData();
  }
}
